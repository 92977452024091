
/*--------------------------------------------------
	Slider and Carousel
---------------------------------------------------*/	

	
.slider.screen-contain {
    width: auto!important;
}

.slider.screen-contain .owl-item img {
    display: block;
    width: auto!important;
    max-height: 60vh!important;
    margin: 0 auto;
}

.slider .owl-stage-outer {
    z-index:0;
}

.slider .owl-controls {
    z-index:1;
}

.slider .owl-nav, .carousel .owl-nav {
    position:absolute;
    top:0;
    left:0;
    margin:0;
    width:100%;
    height:100%;
    pointer-events: none;
    margin:0!important;
    
}

.slider .owl-prev, .carousel .owl-prev {
    text-indent: -9999px;
    position: absolute;
    top: 0;
    pointer-events: auto;
    opacity: 1;
    left: 0;
    cursor: pointer;
    height: 100%;
    width: 50%;
    margin-top: 0;
}

.owl-prev {
    text-decoration:none;
    position:relative;
}
.owl-prev span {	
    text-indent: 0;
    margin-left:-90px;
    display:none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color:#000;
}

.white-tooltip .owl-prev span {
    color:#fff;
}

.owl-prev:hover span {
    display:block;
    position:fixed;
    overflow:hidden;
}

.slider .owl-next, .carousel .owl-next {
    text-indent: -9999px;
    position: absolute;
    top: 0;
    pointer-events: auto;
    opacity: 1;
    right: 0;
    cursor: pointer;
    height: 100%;
    width: 50%;
    margin-top: 0;
}

.owl-next {
    text-decoration:none;
    position:relative;
}
.owl-next span {	
    text-indent: 0;
    display:none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color:#000;
}

.white-tooltip .owl-next span {
    color:#fff;
}

.owl-next:hover span {
    display:block;
    position:fixed;
    overflow:hidden;
}

.owl-dots {
    position:relative;
    width:100%;
    text-align: center;
      -webkit-tap-highlight-color: transparent;
    top:-40px;
}

.carousel .owl-dots {
    top:20px;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; 
}

.owl-dots .owl-dot span {
    background: rgba(255,255,255,0.1)!important;
    width:6px;
    height:6px;
    border-radius:6px;
    width: 8px;
    height: 8px;
    margin: 4px 5px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
}

.owl-dots .owl-dot.active span, .owl-dots .owl-dot:hover span {
    background: #fff!important;
}

.text-carousel .owl-nav {
    text-align: center;
    margin: 0 auto;
    width: 160px;
    height:80px;
    margin-top:30px;
}

.filters-carousel .owl-nav {
    text-align: center;
    margin: 0 auto;
    width: 180px;
    height:80px;
    margin-top:30px;
}

.text-carousel .owl-prev, .text-carousel .owl-next, .filters-carousel .owl-prev, .filters-carousel .owl-next {
    display:inline-block;
    width:80px;
    height:80px;
    margin:0;
    display: flex;
    justify-content: center;
    align-items: center;
    float:left;
}

.filters-carousel .owl-next {
    float:right;
}

.prev-testimonial, .next-testimonial, .prev-filter, .next-filter {		
    width: 34px;
    height: 34px;
    border-radius: 34px;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;		
}

.text-carousel .owl-nav i, .filters-carousel .owl-nav i {
    line-height:34px;
    text-align:center;
    font-size:12px;
    color:#000;
}

.light-content .text-carousel .owl-nav i, .light-content .filters-carousel .owl-nav i {
    color:#fff;
}

.user-review {
    color:#000;
    font-weight:600;
}

.light-content .user-review {
    color:#fff;
}


/*--------------------------------------------------
Light Box
---------------------------------------------------*/	

.image-link {
    display: inline-block;
    width: auto;
}

.mfp-with-zoom .mfp-container, .mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.93;
}

.mfp-with-zoom.mfp-removing .mfp-container, .mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-content figcaption, .mfp-bottom-bar {
    display:none;
}


/*--------------------------------------------------
Video Player
---------------------------------------------------*/	

.hero-video-wrapper {
    position: fixed;
    box-sizing: border-box;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    background-size: cover;
    padding:0;
    overflow:hidden!important;
    opacity:1;		
}

.hero-video-wrapper video.bgvid {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
      background-size: cover;
}

.video-wrapper {
    position: relative;
    box-sizing: border-box;
    min-width: 100%;
    max-height: 100vh;
    width: 100%;
    height: auto;
    background-size: cover;
    padding:0;
    overflow:hidden!important;
    opacity:1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

.video-cover {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:100;
    cursor:pointer;
    background-size:cover;
    background-repeat:no-repeat;
    -webkit-transition: opacity 0.3s ease-out 0.05s, height 0s ease-out 0s;
    transition: opacity 0.3s ease-out 0.05s, height 0s ease-out 0s;
}

.video-cover.hidden {
    opacity:0;
    height: calc(100% - 50px);
    -webkit-transition: opacity 0.3s ease-out, height 0s ease-out 0.3s;
    transition: opacity 0.3s ease-out, height 0s ease-out 0.3s;
}

video.bgvid { 
    background-size: cover;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    width: 100%;
    z-index: -100;
}

.video-wrapper video.bgvid {
    position: relative;
}

.full .video-wrapper video.bgvid {
    position: relative;
    margin-top: 50vh;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.control {
    bottom: 25px;
    color: #ccc;
    display: none;
    left: 0;
    position: absolute;
    width: calc(100% - 40px);
    z-index: 5;
    background-color: #eee;
    padding: 0;
    box-sizing: border-box;
    right: 0;
    margin: 0 auto;
}

.full .control {
    bottom: 10px;
    width: calc(100% - 160px);
}

.btmControl{
    clear:both;
}

.control .sound{
    width: 30px;
    height: 50px;
    float:left;
    cursor:pointer;
    text-align:center;
    line-height:50px;
    color:#fff;
    opacity:0.4;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.control .sound:hover {
    opacity:1;
}

.control .sound i {
    line-height:50px;
}

.control .sound.muted i:first-child {
    display:none;
}

.control .sound.muted i:last-child {
    display:block;
}

.control .sound i:last-child {
    display:none;
}

.control .btnFS{
    width: 50px;
    height: 50px;
    float:left;
    cursor:pointer;
    text-align:center;
    line-height:50px;
    color:#fff;
    opacity:0.4;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.control .btnFS:hover{
    opacity:1;
}

.progress-bar {
    background: rgba(255,255,255,0.5);
    box-sizing: border-box;
    float: left;
    height: auto;
    width: calc(100% - 123px);
    cursor: pointer;
    bottom: 23px;
    position: absolute;
    left: 23px;
    opacity:0;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.video-wrapper:hover .progress-bar {
    opacity:1;
}

.progress {
    height: 2px;
    max-width: 100%;
    position: relative;
    width: 100%;
}

.progress span {
    height:100%;
    position:absolute;
    top:0;
    left:0;
    display:block;
}

.timeBar{
    z-index:10;
    width:0;
    background: #fff;
}

.bufferBar{
    z-index:5;
    width:0;
    background: rgba(255,255,255,0.2);
}

.video-btns {
    position: absolute;
    right: 3px;
    bottom: 0px;
    opacity:0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.video-wrapper:hover .video-btns {
    opacity:1;
}

.volume{
    position:relative;
    cursor:pointer;
    width:70px;
    height:10px;
    float:right;
    margin-top:10px;
    margin-right:10px;
}

.volumeBar{
    display:block;
    height:100%;
    position:absolute;
    top:0;
    left:0;
}


/*--------------------------------------------------
Buttons
---------------------------------------------------*/	

.button-box {
    margin-top:10px;
    line-height:16px;
    cursor:pointer;
    border:none;
    box-sizing:border-box;
    text-align:center;
    display:inline-block;
    position:relative;
}

.text-align-center .button-box {
    margin-left:10px;
    margin-right:10px;
}

.button-content .submit-box {
    color:#fff;
}

.clapat-button-wrap {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    left:0;
    right:0;
    min-width:100px;
    height:60px;		
    pointer-events:initial;
}

.text-align-center .clapat-button-wrap {
    margin:0 auto;
}

.clapat-button {		
    opacity:1;		
}

.button-border {
    height: 40px;
    width: auto;
    border: solid 2px #000;
    background-color:#000;
    border-radius: 5px;
    cursor:pointer;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height:40px;
    overflow: hidden;
    color:#000;
    text-align:center;
}

.light-content .button-border {
    border: solid 2px #fff;
    background-color:#fff;
}

.button-border.rounded {
    border-radius: 30px;
}

.button-border.outline {
    border: solid 2px #000;
    color:#000;
    background-color:transparent;
}

.light-content .button-border.outline {
    border: solid 2px #fff;
    color:#fff;
    background-color:transparent;
}

.button-border span {
    position: relative;
    display: inline-block;
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    width: auto;
    text-align: center;
    transform-origin: 100% 0%;
    padding: 0 30px;
}

.button-border span::before {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    content: attr(data-hover);
    text-align: center;
}

.clapat-button:hover .button-border span {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    transform-origin: 0% 0%;
}



/*--------------------------------------------------
Appearing Items
---------------------------------------------------*/	

.has-animation {
    opacity: 0.01;
    -webkit-transition:translateY(80px);
    transform: translateY(80px);
    transform-origin: 50% 0;
    will-change:transform;
}

.has-animation.has-scale {
    opacity: 0.0001;
    -webkit-transition:translateY(120px) scaleY(1.5);
    transform: translateY(120px) scaleY(1.5);
    transform-origin: 50% 0;
    will-change:transform;
}

.has-mask span {
    display:inline-block;
    overflow:hidden;
}

.has-mask span > span {
    display: inline-block;
    margin:0!important;
    line-height:40px;
    height:40px;
    opacity:0;
    -webkit-transform:translateY(60px);
    transform: translateY(60px);
}

h1.has-mask span, h1.has-mask span > span {
    line-height:60px;
    height:60px;
    margin-right:12px;
}

h2.has-mask span, h2.has-mask span > span {
    line-height:40px;
    height:40px;
    margin-right:10px;
}

h3.has-mask span, h3.has-mask span > span {
    line-height:32px;
    height:32px;
    margin-right:8px;
}	

h4.has-mask span, h4.has-mask span > span {
    line-height:28px;
    height:28px;
    margin-right:7px;
}

h5.has-mask span, h5.has-mask span > span {
    line-height:20px;
    height:20px;
    margin-right:6px;
}

h6.has-mask span, h6.has-mask span > span {
    line-height:16px;
    height:16px;
    margin-right:5px;
}




/*--------------------------------------------------
Underline Link
---------------------------------------------------*/			

a.link {
    display:inline-block;
    position:relative;
}

.light-content a.link {
    color:#fff;
}

a.link::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background: rgba(0,0,0,1);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .2s ease-out;
}

h1 a.link::before {
    bottom: 0px;
    height: 3px;
}

.light-content a.link::before {
    background: rgba(255,255,255,1);
}

a.link:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}



.clapat-icon {
    color: #000;
    position: relative;
    margin-top: 15px;
    margin-bottom: 20px;
}

.clapat-icon i {
    position:relative;
    margin-bottom:0px;
    font-size:24px;
}

.light-content .clapat-icon {
    color:#fff;
}

.clapat-icon:after {
  content: "";
  clear: both;
  display: table;
}


/*--------------------------------------------------
Clients Table
---------------------------------------------------*/	

.clients-table {
    margin:0;
    margin-left:2px;
    display:block;
}

.clients-table:after {
  content: "";
  clear: both;
  display: table;
}

.clients-table li {
    display: block;
    float: left;
    width: 14.3%;
    min-height: 30px;
    
    box-sizing: border-box;
    text-align: center;
    margin: 0px;
    margin-left: 0px;
    margin-left: -1px;
    margin-bottom:-1px;
        
    background: rgba(0,0,0,0.5);
    .paspatout {
      height: 100px;
      text-align: center;
      vertical-align: middle;
      padding:20%;
      display: table-cell;
    }
}

.light-content .clients-table li {
    border: 1px solid #333;
}

.clients-table li img {
    margin:0 auto;
    opacity:0.3;
    transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
}

.clients-table li:hover img {
    opacity:1;
}	



/*--------------------------------------------------
Justified Gallery
---------------------------------------------------*/	

.justified-gallery {
    box-sizing: border-box;
    padding: 0 10px;
    width:calc(100% + 20px) !important;
    margin-left:0px;
}

.collage-thumb {
    position:relative;
    overflow:hidden;
    cursor:pointer;
}

.collage-thumb:hover .thumb-info {
    opacity:1;
    bottom:-5px;
}

.thumb-info {
    position:absolute;
    bottom:-10px;
    left:0;
    width:100%;
    opacity:0;
    color: #fff!important;
    padding: 50px 20px 20px;
    box-sizing:border-box;
    font-size:12px;
    font-weight: 500;
    transition:all 0.2s ease-in-out 0s;
    -webkit-transition:all 0.2s ease-in-out 0s;
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.55) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.55))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}


input[type="submit"] {
    margin:0;
    background:transparent;
    border:none;
    color:#fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding-left:30px;
    padding-right:30px;
}

.light-content input[type="submit"] {
    color:#000;
}

.outline input[type="submit"] {
    color:#000;
}

.light-content .outline input[type="submit"] {
    color:#fff;
}

.error_message {				
    margin-bottom:30px;
    font-size:13px;		
    color:#e93735;		
    font-weight:bold;		
}

#success_page p, #success_page p strong {		
    color:#fff;		
    margin-top:20px;		
}

#message fieldset {		
    border:none;		
}


/*--------------------------------------------------
Google Map
---------------------------------------------------*/	

#map_canvas {
    display: block;
    height:68vh;
    position:relative;
    width: 100%;
}

#hero-image.bgrelative #map_canvas {
    position:relative!important;
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}

.gmnoprint div {
    background:none !important;
}

.gm-style button {
    display:none!important;
}


.services-icon i {
    font-size:24px;
    margin-bottom:20px;
}

.service-info-text {
    margin-bottom:40px;
}



/*--------------------------------------------------
Responsive
---------------------------------------------------*/			
        


@media only screen and (max-width: 1466px) {

.button-border {
    height: 36px;
    line-height: 36px;
}

.button-border span {
    padding: 0 26px;
}

}


@media only screen and (max-width: 1024px) {

.slider .owl-prev, .slider .owl-next {
    display:none!important;
}

.text-align-center .clapat-icon {
    padding-left: 0;
}

.text-carousel {
    max-width:500px;
    margin: 0 auto;
}

#mc_embed_signup .submit-box {
    margin-top: 30px;
}

textarea {
    margin-bottom: 30px;
}

.justified-gallery {
    margin-left:-10px;
    padding:0;
}

.slider .owl-nav, .carousel .owl-nav {
    display:none;
}

.full .video-wrapper video.bgvid {
    position: relative;
    margin-top: 0;
    left: 0;
    transform: translateX(0) translateY(0);
}

.full .control {
    bottom: 10px;
    width: calc(100% - 100px);
}

#map_canvas {
    height:50vh;
}
    
h1.has-mask span, h1.has-mask span > span {
    line-height:50px;
    height:50px;
    margin-right:12px;
}

h2.has-mask span, h2.has-mask span > span {
    line-height:40px;
    height:40px;
    margin-right:10px;
}

}


@media only screen and (max-width: 767px) {

.text-carousel .owl-prev, .text-carousel .owl-next {
    width:80px;
    height:80px;
    margin:0;
    padding:23px;
    box-sizing:border-box;
    display: inline-block;
    justify-content: center;
    align-items: center;
    float:none;
}

.video-wrapper {
    max-height: 50vh;
}

.control {
    display:none;
}

.name-box, .email-box {
    width: 100%;
    margin-right: 0;
}

.clients-table li {
    width: 80%;
}

.empty-space, .empty-space.tx { 
    height:60px;
}

input[type="text"], textarea {
    font-size: 14px;
}

}


@media only screen and (max-width: 479px) {
    
.clapat-button {
    margin-bottom: 20px;
}

.text-carousel {
    max-width: 280px;
}

.text-carousel .owl-prev, .text-carousel .owl-next {
    margin: 0;
}
    
}			
