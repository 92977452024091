
	#showcase-holder {
		width:100%;
		height:80vh;
		position:relative;
		perspective: 1000px;
		overflow:hidden;
	}
	
	#showcase-tilt-wrap {
		width:100%;
		height:90vh;
		position:absolute;
		perspective: 1000px;
		overflow:hidden;
	}
	
	#showcase-tilt {
		width:100%;
		height:100%;
		position:fixed;
		overflow:visible;
		transform-style: preserve-3d;
		-webkit-transform: scale(1.05);
		transform: scale(1.05);	
	}
	
	#showcase-tilt.disabled {
		-webkit-transform: translate3d(0, 0, 0) scale(1.05)!important;
		transform: translate3d(0, 0, 0) scale(1.05)!important;
		-webkit-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
	}

	#showcase-slider {
		height: 100%;
		width: 100%;
	}
	
	.swiper-slide {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
		-webkit-user-select: none;
		-webkit-touch-callout: none; 
		user-select: none;		
	}
	
	.swiper-slide::after {
		content: "";
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
	}
	
	.img-mask {	
		width:100%;
		height:100%;
		top:0;
		bottom:0;
		left:0;
		right:0;
		margin:0 auto;
		display: table;
		position:relative;
		box-sizing:border-box;
		-webkit-transition: 1.2s ease-in-out;
		transition: 1.2s ease-in-out;		
	}
	
	.section-image {
		position:relative;
		width:100%;
		height:100vh;
		background-repeat:no-repeat;
		background-position:center center;
		background-size: cover;
		opacity:1;		 
	}
	
	.title {
		font-weight: 700;
		font-family: 'Poppins', sans-serif;
		position: relative;
		display:block;
		margin-bottom:0px;
		display:inline-block;
		vertical-align: top;
		width:auto;
		font-size:3.5vw;
		line-height:90px;
	}

	.showcase-content {
		font-weight: 500;
		font-family: 'Poppins', sans-serif;
		position: relative;
		display:block;
		margin-bottom:0px;
		display:inline-block;
		vertical-align: top;
		width:80%;
		font-size:12px;
		line-height:15px;
		opacity: 0;
		-webkit-transform: translateX(20px);
		display: flex;
		flex-direction: row;
		margin: 20px auto;
		span, h3 {
			padding: 2px 6px;
		}
	}
	.swiper-pagination-bullet-active .showcase-content {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
	
	.swiper-pagination-bullet:first-child .title {
		opacity: 0;
		-webkit-transform: translateY(15vh) scale(1);
		transform: translateY(15vh) scale(1);
	}
	
	.title span {
		display: block;
		float:left;
		margin: 0;
		min-width: 20px;
		width:auto;
		line-height:90px;
		height:90px;
		box-sizing: border-box;
		opacity: 0;
		-webkit-transform: translateX(100px) scale(1.1);
		transform: translateX(100px) scale(1.1);
	}
	
	.swiper-pagination-bullet:first-child .title span {
		opacity: 1;
		-webkit-transform: translateX(0px) scale(1);
		transform: translateX(0px) scale(1);
	}
	
	.subtitle {
		margin-bottom: 0;
		opacity: 1;
		line-height: 20px;
		position: relative;
		left: 0;
		display: block;
		font-size:14px;
		font-weight: 600;
		font-family: 'Poppins', sans-serif;
		opacity: 0;
		-webkit-transform: translateX(20px) scale(1);
		transform: translateX(20px) scale(1);
	}
	
	.swiper-pagination-bullet:first-child .subtitle {
		opacity: 0;
		-webkit-transform: translateY(15vh) scale(1);
		transform: translateY(15vh) scale(1);
	}
	
	#showcase-holder .showcase-pagination-wrap {
		position: absolute;
		right: 0;
		left: 0;
		margin: 0 auto;
		top: 0;
		box-sizing: border-box;
		width: 100%;
		z-index: 100;
		bottom: 0;
		height: 120vh;
		display: flex;
		pointer-events: none;
		transform: translateY(-32vh)!important;
		-webkit-transform: translateY(-32vh)!important;
	}

	#section-cases #showcase-holder .showcase-pagination-wrap {
		transform: translateY(-10vh);
		-webkit-transform: translateY(-10vh);
	}

	#section-cases  .showcase-pagination .swiper-pagination-bullet {
    transform: translateY(0vh);
		.title {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 90px;
			span {
				height: 55px;
			}
		}
	}

	#section-cases {
		.section-image {
			opacity: .3;
		}
	}

	
	#showcase-holder .showcase-pagination {
		width:100%;
		height:100%;
		display: table-cell;
		vertical-align: bottom;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		position:relative;
		pointer-events:none;
	}
	
	#showcase-holder .swiper-pagination-bullet {
		position: absolute;
		width: 100%;
		height: auto;
		display: block;
		border-radius: 0;
		background: transparent;
		box-sizing: border-box;
		text-align: center;
		opacity: 1;
		bottom: 0;
	
	}
	
	#showcase-holder .swiper-pagination-bullet {
		color: rgba(0,0,0,1);
		text-align: center;
	}
	
	.light-content #showcase-holder .swiper-pagination-bullet {
		color: rgba(255,255,255,1);
		text-align: center;
	}
	
	
	.showcase-counter {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity:0;
	}
	
	.counter-wrap {
		-webkit-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out;
	}
	
	.counter {
		height:20px;
		width:50px;
		position: absolute;
		bottom: 38px;
		left: 230px;
		text-align:center;
		opacity: 0;
		font-size:14px;
		font-weight:500;
		color:#000;
		line-height: 20px;
		-webkit-transform: translateY(20px) scale(1);
		transform: translateY(20px) scale(1);		
	}
	
	.light-content .counter, .light-content .showcase-counter::after {
		color:#fff;
	}
	
	.showcase-counter::after {
		content: attr(data-total);
		width: 20px;
		height: 20px;
		font-size:14px;
		line-height: 20px;
		position: absolute;
		bottom: 38px;
		left: 300px;
		opacity: 1;
		font-weight:500;
		color:#000;
		-webkit-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out;
	}
	
	.showcase-counter::before {
		content: '';
		width: 1px;
		height: 10px;
		position: absolute;
		bottom: 44px;
		left: 282px;
		background-color:rgba(0,0,0,0.5);
		-webkit-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out;
	}
	
	.light-content .showcase-counter::before {
		background-color:rgba(255,255,255,0.5);
	}
	
	.scale-up .counter-wrap, .scale-up .showcase-counter::after, .scale-up .showcase-counter::before {
		opacity:0!important;
	}
	
	.swiper-pagination-bullet:first-child .counter {
		opacity: 0;
		-webkit-transform: translateY(0px) scale(1);
		transform: translateY(0px) scale(1);
	}
	
	.caption-border {
		height:1px;
		background-color:rgba(0,0,0,0.15);
		position:absolute;
		margin:0 auto;
		bottom:50px;
		transition: width 0.8s cubic-bezier(0.68, 0, 0.265, 1) 0.2s;
		-webkit-transition: width 0.8s cubic-bezier(0.68, 0, 0.265, 1) 0.2s;	
	}
	
	.light-content .caption-border {
		background-color:rgba(255,255,255,0.15);
	}
	
	.caption-border.left {
		left:400px;
    top:200px;
	}
	
	.caption-border.right {
		right:400px;
	}
	
	.caption-border.left::before {
		content: '';
		width: 90px;
		height: 1px;
		position: fixed;
		left: 80px;
		bottom:50px;
		background-color: rgba(0,0,0,0.15);
	}
	
	.caption-border.right::after {
		content: '';
		width: 90px;
		height: 1px;
		position: fixed;
		right: 80px;
		bottom:50px;
		background-color: rgba(0,0,0,0.15);
	}
	
	.light-content .caption-border.left::before, .light-content .caption-border.right::after {
		background-color: rgba(255,255,255,0.15);
	}
	
	.btn-hold-progress-bar {
		background: #fff;
		width: 100%;
		height: 2px;
		position: absolute;
		bottom: 0;
	}
	
	.caption-border.left .btn-hold-progress-bar {
		right:200px;
	}
	
	.caption-border.right .btn-hold-progress-bar {
		left:200px;
	}
	
	.arrows-wrap {
		width: 160px;
		height: 80px;
		position: absolute;
		right: 200px;
		pointer-events: initial;
		bottom:10px;
		pointer-events:initial;
		opacity:0;
	}
	
	.prev-wrap, .next-wrap {
		width:80px;
		height:80px;
		float:left;
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
	}
	
	
	
	.arrows-wrap .swiper-button-next, .arrows-wrap .swiper-button-prev {
		position: relative;
		top: auto;
		width: 40px;
		height: 40px;
		margin-top: 0;
		z-index: 10;
		cursor: pointer;
		background-size: 11px 20px;
		background-position: center;
		background-repeat: no-repeat;
		left: auto;
		right: auto;
		/* background-image:none!important;  */
	}
	/*
	.arrows-wrap .swiper-button-prev::before {
		height: 40px;
		width: 40px;
		position: absolute;
		left:0;
		font-size: 30px;
		line-height:40px;
		text-align:center;		
		font-family: 'FontAwesome';
		content: "\f104";
		color: #000;
		-webkit-transition: 0.15s ease-in-out;
		transition: 0.15s ease-in-out;
	}
	
	.arrows-wrap .swiper-button-next::before {
		height: 40px;
		width: 40px;
		position: absolute;
		left:0;
		font-size: 30px;
		line-height:40px;
		text-align:center;		
		font-family: 'FontAwesome';
		content: "\f105";
		color: #000;
		-webkit-transition: 0.15s ease-in-out;
		transition: 0.15s ease-in-out;
	}*/
	
	.light-content .arrows-wrap .swiper-button-prev::before, .light-content .arrows-wrap .swiper-button-next::before {
		color: #fff;
	}
	
	.arrows-wrap .swiper-button-next.swiper-button-disabled, .arrows-wrap .swiper-button-prev.swiper-button-disabled {
		opacity: 1;
	}
	
	.footer-button-wrap {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		position:fixed;
		bottom:10px;
		left:0;
		right:0;
		max-width:180px;
		height:60px;
		margin:0 auto;
		pointer-events:initial;
		opacity:0;
		transform: translateY(15vh);
		-webkit-transform: translateY(15vh);
	}
	
	.footer-button {		
		opacity:1;		
	}
	
	.button-border {
		height: 40px;
		width: 140px;
		border: solid 2px #fff;
		border-radius: 30px;
		cursor:pointer;
		font-weight: 600;
		font-family: 'Poppins', sans-serif;
		font-size: 14px;
		line-height:40px;
		overflow: hidden;
		color:#fff;
		text-align:center;		
		
	}
	
	.button-border span {
		position: relative;
		display: inline-block;
		-webkit-transition: -webkit-transform 0.2s;
		transition: transform 0.2s;
		width:140px;
		text-align:center;
		transform-origin: 100% 0%;
	}
	
	.button-border span::before {
		position: absolute;
		width:140px;
		top: 100%;
		left:0;
		content: attr(data-hover);
	}
	
	.footer-button:hover .button-border span {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		transform-origin: 0% 0%;
	}
	
	.button-wrap {
		position:relative;		
		cursor:pointer;
		top:0px;
		pointer-events:initial;		
	}
	
	.button-wrap.left {
		float:left;
		left:-32px;	
	}
	
	.button-wrap.right {
		float:right;
		right:-32px;	
	}
	
	.icon-wrap {
		width: 80px;
		height: 80px;
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		float:left;
	}
	
	.button-wrap.left .icon-wrap {
		float:left;
	}
	
	.button-wrap.right .icon-wrap {
		float:right;
	}
	
	.button-icon {
		height: 40px;
		width: 40px;
		color: #fff;
		text-align: center;
		line-height: 30px;
		font-size: 22px;
	}
	
	.plus-img {
		width:30px;
		height:30px;
		position:absolute;
		top:50%;
		left:50%;
		margin-left:-15px;
		margin-top:-15px;
		z-index:10;
	}
	
	.plus-img span:first-child:before {
		content:"";
		height: 20px;
		width: 2px;
		background-color: #fff;
		position: absolute;
		left: 14px;
		top:5px;
		border-radius:2px;
		transform: scaleY(1);
		transform-origin: top;
		transition: transform .2s ease-in 0.1s;
		-webkit-transition: transform .2s ease-in 0.1s;
	}
  
    .button-wrap:hover .plus-img span:first-child:before {
		transform: scaleY(0);
		transform-origin: bottom;
		transition: transform .2s ease-out;
		-webkit-transition: transform .2s ease-out;
	}
	
	.plus-img span:last-child:before {
		content:"";
		height:2px;
		width:20px;
		background-color:#fff;
		position: absolute;
		top:14px;
		left:5px;
		border-radius:2px;
		transform: scaleX(1);
		transform-origin: left;
		transition: transform .2s ease-in 0.2s;
		-webkit-transition: transform .2s ease-in 0.2s;
	}
	
	.button-wrap:hover .plus-img span:last-child:before {
		transform: scaleX(0);
		transform-origin: right;
		transition: transform .2s ease-out 0.1s;
		-webkit-transition: transform .2s ease-out 0.1s;
	}
	
	.plus-img span:first-child:after {
		content:"";
		height: 20px;
		width: 2px;
		background-color: #fff;
		position: absolute;
		left: 14px;
		top:5px;
		border-radius:2px;
		transform: scaleY(0);
		transform-origin: bottom;
		transition: transform .2s ease-in;
		-webkit-transition: transform .2s ease-in;
	}
  
    .button-wrap:hover .plus-img span:first-child:after {
		transform: scaleY(1);
		transform-origin: top;
		transition: transform .2s ease-out 0.1s;
		-webkit-transition: transform .2s ease-out 0.1s;
	}
	
	.plus-img span:last-child:after {
		content:"";
		height:2px;
		width:20px;
		background-color:#fff;
		position: absolute;
		top:14px;
		left:5px;
		border-radius:2px;
		transform: scaleX(0);
		transform-origin: right;
		transition: transform .2s ease-in;
		-webkit-transition: transform .2s ease-in;
	}
	
	 .button-wrap:hover .plus-img span:last-child:after {
		transform: scaleX(1);
		transform-origin: left;
		transition: transform .2s ease-out 0.2s;
		-webkit-transition: transform .2s ease-out 0.2s;
	}

	.button-text {
		font-weight: 600;
		font-family: 'Poppins', sans-serif;
		font-size: 12px;
		line-height:30px;
		margin:25px 0;
		overflow: hidden;
		width:140px;
		color:#000;		
	}
	
	.light-content .button-text {
		color:#fff;		
	}
	
	.button-wrap.left .button-text {
		text-align:left;
		float: left;
	}
	
	.button-wrap.right .button-text {
		text-align:right;
		float: right;
	}
	
	.button-text span {
		position: relative;
		display: inline-block;
		-webkit-transition: -webkit-transform 0.2s;
		transition: transform 0.2s;
		width:140px;
		text-align:center;
		transform-origin: 100% 0%;;
	}
	
	.button-wrap.left .button-text span {
		text-align:left;
	}
	
	.button-wrap.right .button-text span {
		text-align:right;
	}
	
	.button-text span::before {
		position: absolute;
		width:140px;
		top: 100%;
		left:0;
		content: attr(data-off);
	}
	
	.button-wrap:hover .button-text span {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		transform-origin: 0% 0%;;
	}
	
	.bars {
		height: 40px;
		left:7px;
		position: relative;
		width: 40px;
		cursor: pointer;
	}
	
	.bars .bar {
		background: #000;
		bottom: 12px;
		height: 3px;
		position: absolute;
		width: 2px;      
		animation: sound 0ms -800ms linear infinite alternate;
	}
	
	.light-content .bars .bar {
		background: #fff;
	}
	
	.mute .bars .bar {
		height: 2px;
		bottom:19px;     
		animation:none;
	}
	
	@keyframes sound {
		0% {
		   opacity: 1;
			height: 16px;
			-webkit-transform: scaleY(0.1);
			transform: scaleY(0.1);
		}
		100% {
			opacity: 1;       
			height: 16px; 
			-webkit-transform: scaleY(1);
			transform: scaleY(1);       
		}
	}
	
	@keyframes sound-disabled {
		0% {
		   opacity: 1;
			height: 3px; 
		}
		100% {
			opacity: 1;       
			height: 16px;        
		}
	}
	
	.bar:nth-child(1)  { left: 4px; animation-duration: 674ms; }
	.bar:nth-child(2)  { left: 8px; animation-duration: 733ms; }
	.bar:nth-child(3)  { left: 12px; animation-duration: 507ms; }
	.bar:nth-child(4)  { left: 16px; animation-duration: 458ms; }
	.bar:nth-child(5)  { left: 20px; animation-duration: 700ms; }
	.bar:nth-child(6)  { left: 24px; animation-duration: 627ms; }	
	
	#showcase-carousel {
		position: relative;
		width: calc(100% - 80px);
		margin: 0 auto;
		height:100vh;		
		overflow:visible;	
	}
	
	#showcase-carousel .swiper-wrapper {
		align-items: center;	
	}
	
	#showcase-carousel .swiper-slide {
		height: 19vw;
		overflow: visible;
		display: flex;
		top:-30px;
		cursor:pointer;	
	}
	
	#showcase-carousel .swiper-slide::after {
		display:none;
	}
	
	.section-wrap-image {
		width: 100%;
		height: 100%;
		position:absolute;
		-webkit-clip-path: inset(8px 8px 8px 8px);
		clip-path: inset(8px 8px 8px 8px);
		-webkit-transition: 1s cubic-bezier(.075,.82,.165,1);
		-moz-transition: 1s cubic-bezier(.075,.82,.165,1);
		transition: 1s cubic-bezier(.075,.82,.165,1);		
	}
	
	#showcase-carousel .swiper-slide:hover .section-wrap-image {		
		-webkit-clip-path: inset(0 0 0 0);
		clip-path: inset(0 0 0 0);		
	}
	
	#showcase-carousel .section-image {
		position: absolute;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		opacity: 1;
	}
	
	.section-image::after {
		content: "";
		opacity:0;
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
		-webkit-transition: all 0.6s ease-in-out;
		transition: all 0.6s ease-in-out;
	}
	
	.load-project-thumb .section-image::after {
		content: "";
		opacity:1;
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
		-webkit-transition: all 0.6s ease-in-out;
		transition: all 0.6s ease-in-out;
	}
	
	
	#showcase-carousel .slide-caption {
		z-index: 10;
		position: absolute;
		bottom: -60px;
		height: 60px;
		padding-top: 25px;
		padding-left:40px;
		box-sizing: border-box;
		width: 100%;
		cursor:default;
		left:0;
	}
	
	.scale-up #showcase-carousel .slide-caption {
		-webkit-transform: translateX(0) !important;
		transform: translateX(0) !important;
	}
	
	#showcase-carousel .slide-title {
		font-size: 16px;
		line-height: 20px;
		margin: 0;
		font-weight: 700;
		display: block;
		float:left;
		position:relative;
	}
	
	#showcase-carousel .slide-title::after {
		content: '';
		width: 20px;
		height: 1px;
		background-color: #333;
		position: absolute;
		top: 9px;
		right: -40px;
	}
	
	#showcase-carousel .slide-cat {
		display:block;
		float:left;
		font-size: 14px;
		font-weight: 500;
		opacity: 0.3;
		line-height: 20px;
		overflow:hidden;
		margin-left: 55px;
	}

	#showcase-carousel .slide-cat span {
		position: relative;
		display: block;
		width:120px;
		-webkit-transition: -webkit-transform 0.2s;
		transition: transform 0.2s;
		transform-origin: 100% 0%;
	}
	
	#showcase-carousel .slide-cat span::before {
		position: absolute;
		top: 100%;
		width:100%;
		left:0;
		content: attr(data-hover);
	}
	
	#showcase-carousel .swiper-slide:hover .slide-cat span, #showcase-carousel .swiper-slide.above .slide-cat span {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		transform-origin: 0% 0%;
	}
	
	.swiper-scrollbar {
		position: absolute;
		text-align: center;
		-webkit-transition: .3s opacity;
		-o-transition: .3s opacity;
		transition: .3s opacity;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
		z-index: 10;
		width: 25%;
		height: 3px;
		background: rgba(255,255,255,0) !important;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 40px;
		pointer-events:false;
		transform-origin: right;
	}
	
	.swiper-scrollbar::after {
		content: '';
		background: rgba(255,255,255,0.1);
		height: 1px;
		width: 100%;
		left: 0;
		top: 1px;
		position: absolute;
	}
	
	.swiper-scrollbar-drag {
		background-color:#fff!important;
		pointer-events:initial;
		z-index:1;
	}
	
	
	
	
	
	
	
	
	#large-showcase-carousel {
		position: relative;
		width: calc(100% - 80px);
		margin: 0 auto;
		height:100vh;		
		overflow:visible;	
	}
	
	#large-showcase-carousel .swiper-wrapper {
		align-items: center;	
	}
	
	#large-showcase-carousel .swiper-slide {
		height: 30vw;
		width:50vw!important;
		overflow: visible;
		display: flex;
		top:-30px;
		cursor:pointer;	
	}
	
	#large-showcase-carousel .swiper-slide::after {
		display:none;
	}
	
	#large-showcase-carousel .section-wrap-image {
		width: 100%;
		height: 100%;
		position:absolute;
		-webkit-clip-path: inset(8px 8px 8px 8px);
		clip-path: inset(8px 8px 8px 8px);
		-webkit-transition: 0.7s cubic-bezier(.075,.82,.165,1);
		-moz-transition: 0.7s cubic-bezier(.075,.82,.165,1);
		transition: 0.7s cubic-bezier(.075,.82,.165,1);	
	}
	
	.scale-up #large-showcase-carousel .section-wrap-image, .scale-up #large-showcase-carousel .swiper-slide:hover .section-wrap-image {
		-webkit-clip-path: inset(50px 8p0x 50px 80px);
		clip-path: inset(50px 80px 50px 80px);
		-webkit-transition: 0.7s cubic-bezier(.075,.82,.165,1);
		-moz-transition: 0.7s cubic-bezier(.075,.82,.165,1);
		transition: 0.7s cubic-bezier(.075,.82,.165,1);			
	}
	
	#large-showcase-carousel .swiper-slide:hover .section-wrap-image {		
		-webkit-clip-path: inset(0 0 0 0);
		clip-path: inset(0 0 0 0);		
	}
	
	#large-showcase-carousel .section-image {
		position: absolute;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		opacity: 1;
	}
	
	#large-showcase-carousel .hero-video-wrapper {
		position:absolute;
		z-index:1;
	}
	
	.section-image::after {
		content: "";
		opacity:0;
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
		-webkit-transition: all 0.6s ease-in-out;
		transition: all 0.6s ease-in-out;
	}
	
	.load-project-thumb .section-image::after {
		content: "";
		opacity:1;
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
		-webkit-transition: all 0.6s ease-in-out;
		transition: all 0.6s ease-in-out;
	}
	
	
	#large-showcase-carousel .slide-caption {
		z-index: 10;
		position: absolute;
		bottom:27%;
		height: 120px;
		padding-left:0;
		box-sizing: border-box;
		width: 100%;
		cursor:default;
		left:-60px;
		pointer-events:none;
		-webkit-transition: 0.5s cubic-bezier(.075,.82,.165,1) 0.6s;
		transition: 0.5s cubic-bezier(.075,.82,.165,1) 0.6s;
	}
	
	#large-showcase-carousel .swiper-slide-active .slide-caption {
		-webkit-transition: 0.5s cubic-bezier(.075,.82,.165,1) 0s;
		transition: 0.5s cubic-bezier(.075,.82,.165,1) 0s;
	}
	
	.scale-up #large-showcase-carousel .slide-caption{
		-webkit-transform: translateX(60px) ;
		transform: translateX(60px);
		opacity:0;
		-webkit-transition: 0.5s cubic-bezier(.075,.82,.165,1) 0s;
		transition: 0.5s cubic-bezier(.075,.82,.165,1) 0s;
	}
	
	#large-showcase-carousel .swiper-slide.above .slide-caption{
		-webkit-transform: translateX(-20px) ;
		transform: translateX(-20px);
		opacity:0;
		-webkit-transition: 0.6s cubic-bezier(.075,.82,.165,1) 0s;
		transition: 0.6s cubic-bezier(.075,.82,.165,1) 0s;
	}
	
	#large-showcase-carousel .slide-title {
		font-size: 3.5vw;
		line-height: 90px;
		margin: 0;
		font-weight: 700;
		display: block;
		position:relative;
		width:100%;
	}
	
	#large-showcase-carousel .slide-title span {
		line-height:90px;
		height:90px;
		display: block;
		float:left;
		min-width:20px;
		width:auto;
	}
	
	#large-showcase-carousel .slide-cat {
		display:block;
		font-size: 14px;
		font-weight: 600;
		opacity: 1;
		line-height: 20px;
		overflow:hidden;
		margin-left:3px;
		width:100%;
	}

	#large-showcase-carousel .slide-cat span {
		position: relative;
		display: block;
		width:120px;
	}
	
	
/*--------------------------------------------------
	16. Responsive
---------------------------------------------------*/			
			
@media only screen and (max-width: 3600px) {
	#showcase-holder .showcase-pagination-wrap {
		transform: translateY(-55vh)!important;
		-webkit-transform: translateY(-55vh)!important;
	}
}

@media only screen and (max-width: 1466px) {
		
	#showcase-holder .showcase-pagination-wrap {
		transform: translateY(-55vh)!important;
		-webkit-transform: translateY(-55vh)!important;
	}
	.title {
    	font-size: 4.5vw;
		line-height: 70px;
	}
	
	.title span {
		line-height: 70px;
		height: 70px;	
	}
	
	.counter {
    	left: 100px;
		bottom:28px;
	}
	
	.showcase-counter::before {
		bottom: 33px;
		left: 145px;
	}
	
	.showcase-counter::after {
    	left: 160px;
		bottom:28px;
	}
	
	.arrows-wrap {
		width: 100px;
		height: 50px;
		right: 95px;
		bottom: 16px;
	}
	
	.prev-wrap, .next-wrap {
		width: 50px;
		height: 50px;
	}
	
	.caption-border {
    	bottom: 40px;
	}
	
	.caption-border.left {
		left: 200px;
	}
	
	.caption-border.left::before {
		width: 40px;
		left: 50px;
		bottom: 40px;
	}
	
	.caption-border.right {
		right: 200px;
	}
	
	.caption-border.right::after {
		width: 40px;
		right: 50px;
		bottom: 40px;
	}
	
	#showcase-carousel {
		width: calc(100% - 80px);
	}
	
	#showcase-carousel .swiper-slide {
		height: 27vw;
	}
	
	#large-showcase-carousel .slide-title {
		font-size: 4.5vw;
		line-height: 70px;
	}
	
	#large-showcase-carousel .slide-title span {
		line-height: 70px;
		height: 70px;	
	}
	
}


@media only screen and (max-width: 1024px) {
	#showcase-holder .showcase-pagination-wrap {
	transform: translateY(-37vh)!important;
	-webkit-transform: translateY(-37vh)!important;
	}
  #showcase-holder {
    height:102vh;
  }
	
	.title {
    	font-size: 5.5vw;
	}
	
	.counter {
    	left: 20px;
	}
	
	.showcase-counter::before {
		left: 65px;
	}
	
	.showcase-counter::after {
    	left: 80px;
	}
	
	.arrows-wrap {
		right: 25px;
	}
	
	.caption-border.left {
		left: 130px;
	}
	
	.caption-border.right {
		right: 130px;
	}
	
	.caption-border.left::before, .caption-border.right::after {
		display:none;
	}
	
	#showcase-carousel {
		width: calc(100% - 60px);
	}
	
	.section-wrap-image {
		-webkit-clip-path: inset(0px 0px 0px 0px);
		clip-path: inset(0px 0px 0px 0px);
	}
	
	#showcase-carousel .slide-caption {
		padding-top: 20px;
		padding-left: 20px;
	}
	
	.swiper-scrollbar {
		display:none;
	}
	
	#large-showcase-carousel .swiper-slide {
		height: 40vw;
		width:70vw!important;
	}
	
	#large-showcase-carousel .slide-caption {
		left: -40px;
	}
	
	#large-showcase-carousel .slide-title {
		font-size: 5.5vw;
	}

	.fact-items  {
    align-items: center;
    flex-direction: column;
		.fact-item {
			width: 60%;
			margin-top: 30px;
		}
	}
	
	.showcase-content {
		flex-direction: column;
		max-width: 50%;
		font-size: 14px;
		.part-1, .part-2, .part-3 {
			margin-top: 10px;
		}
	}
	#section-cases .showcase-pagination .swiper-pagination-bullet  {
    transform: translateY(0px);
	}
	#section-cases .title {
    margin-bottom: 50px;
		font-size: 3.5vw;
	}
	#section-cases #showcase-holder {
    height: 100vh;
	}
}

@media only screen and (max-width: 860px) {
	#showcase-holder .showcase-pagination-wrap {
		transform: translateY(-30vh);
		-webkit-transform: translateY(-30vh);
		height: 100vh;
	}
  #showcase-holder {
      height:100vh;
    }
}

@media only screen and (max-width: 767px) {
	
  #section-cases .showcase-pagination .swiper-pagination-bullet.tab__link {
    position: relative!important;
      .showcase-content {
        transform: none;
        max-width: 100%;

      }
  }
	#showcase-holder .showcase-pagination-wrap {
		transform: translateY(-30vh);
		-webkit-transform: translateY(-30vh);
		height: 100vh;
	}
  #showcase-holder {
    height: 100vh;
  }

	.title {
    	font-size: 7vw;
	}
	
	.title span {
    	min-width: 10px;
	}
	
	.counter {
    	display:none;
	}
	
	.showcase-counter::before {
		display:none;
	}
	
	.showcase-counter::after {
    	display:none;
	}
		
	.arrows-wrap {
		display:none!important;
	}
	
	#showcase-carousel .swiper-slide {
		height: 50vw;
	}
	
	.caption-border.left {
		left: 30px;
	}
	
	.caption-border.right {
		right: 30px;
	}
	
	.scale-up #large-showcase-carousel .section-wrap-image, .scale-up #large-showcase-carousel .swiper-slide:hover .section-wrap-image {
		-webkit-clip-path:  inset(8px 8px 8px 8px);	
		clip-path: inset(8px 8px 8px 8px);	
	}
	
	#large-showcase-carousel .slide-caption {
		left: 0;
		text-align: center;
		bottom: calc(50% - 80px);
		height: 80px;	
	}
	
	#large-showcase-carousel .swiper-slide.above .slide-caption{
		-webkit-transform: translateX(0px) ;
		transform: translateX(0px);
	}
	
	.scale-up #large-showcase-carousel .slide-caption{
		-webkit-transform: translateX(0px) ;
		transform: translateX(0px);
	}
	
	#large-showcase-carousel .slide-title {
		font-size: 6vw;
		display: inline-block;
		width: auto;
		line-height: 50px;
	}
	
	#large-showcase-carousel .slide-title span {
		line-height: 50px;
		height:50px;
		min-width: 10px;	
	}
	
	#large-showcase-carousel .slide-cat span {
		width: auto;
	}
		

	.showcase-content {
		flex-direction: column;
		.part-1, .part-2, .part-3 {
			max-width: 100%;
		}
	}
	
}


@media only screen and (max-width: 479px) {
	
	#showcase-holder .showcase-pagination-wrap {
		transform: translateY(-35vh)!important;
		-webkit-transform: translateY(-35vh)!important;
		height: 100vh;
	}

	#showcase-tilt-wrap {
		height: 110vh;
	}
	
	.title {
    	font-size: 9vw;
	}
	
	.title span {
    	min-width: 10px;
	}
	
	.caption-border.left {
		left: 20px;
	}
	
	.caption-border.right {
		right: 20px;
	}
	
	#showcase-carousel {
		width: calc(100% - 40px);	
	}
	
	#showcase-carousel .slide-caption {
		padding-top: 20px;
		padding-left:0px;
		text-align: center;
	}
	
	#showcase-carousel .slide-title {
		float: none;
	}
	
	#showcase-carousel .slide-title::after {
		display:none;
	}
	
	#showcase-carousel .slide-cat {
		margin-left: 0px;	
		font-size:12px;
		float:none;
	}
	
	#showcase-carousel .slide-cat span {
		width: auto;
	}
	
	#large-showcase-carousel .swiper-slide {
		height: 50vw;
		width:80vw!important;
	}
	
	#large-showcase-carousel .slide-title {
		font-size: 6vw;
		line-height: 30px;
	}
	
	#large-showcase-carousel .slide-title span {
		line-height: 30px;
		height:30px;
		min-width: 10px;	
	}
	
}			
