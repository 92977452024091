#about-wrapper {
  background: #2f2f2f; //radial-gradient(circle, #262824 0%, #2b2b2b 35%, #191714 100%);
  margin-bottom: 100px;
  border-top: 1px solid #2f2f2f;
  width:100%;
  padding-bottom: 30px;
}
#about-wrapper::before {
  background-repeat: no-repeat;
  left: 0;
  background-size: 100% 100%;
  content: '';
  display: block;
  height: 50px;
  position: relative;
  margin-top: -50px;
  width: 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%232f2f2f%3B' /%3E%3C/svg%3E");
  box-shadow: inset 0 -1px 0 0
  #2f2f2f, 0 1px 0 0 #2f2f2f;
}
#about-text {
  padding: 60px 0;
  border-bottom: 1px solid #2f2f2f;
  .intro {
    padding: 0 20px;
  }
  .intro-text {
    max-width: 90%;
    margin: 0 auto;
  }
}