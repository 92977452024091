.fact-element {
  padding: 70px 50px 40px;
  background-color: #101010; 
  margin-top: 70px;

  & .fact-headline {
    & h3 .material-icons {
      font-size:150px;
      line-height: 150px;
      color: #777;
    }
    & h3 {
      font-size: 70px;
      line-height: 80px;
      color: #777!important;
    }
  }

  & .fact-list {
    & ul {
      list-style-type: none;
      width: 100%;
      margin: 0;
      & li.fact-item {
        border-bottom: 2px solid #777;
        padding: 20px 20px;
        margin-bottom: 0;
        & strong {
          color: #FFF;
        }
        & span {
          float: right;
          color: #FFF;
        }
      }
      & li.fact-item:last-child {
        border-bottom: 0;
      }
    }
  }
}


@media only screen and (max-width: 1024px) {
  .fact-element {
    & .fact-headline {
      & h3 {
        font-size: 50px;
        line-height: 40px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .fact-element {
    & .fact-headline {
      & h3 {
        font-size: 50px;
        line-height: 40px;
      }
    }
  }
}