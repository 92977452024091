table {
  margin-top: 50px;
  thead {
    th {
      color: #FFF;
      font-weight: bold;
    }
  }
  tbody {
    tr.odd {
      td {
        background: rgba(5, 5, 5, 0.7);
      }
    }
    tr.even {
      td {
        background: rgba(10, 10, 10, 0.9);
      }
    }
    tr {
      td {
      }
    }
    tr.no-border {
      td {
        border: 0;
      }
    }
    tr.selected.odd {
      td {
        background-color: map-get($wiesel-green, 800);
        color: $black;
        strong, p, input {
          color: $black;
        }
      }
    }
    tr.selected.even {
      td {
        background-color: map-get($wiesel-green, 900);
        color: $black;
        strong, p, input {
          color: $black;
        }
      }
    }
    tr.category {
      td {
        border-top: none;
      }
    }
  }
  tfoot {
    tr {
      td {
      }
    }
    tr.no-border {
      td {
        border: 0;
      }
    }
  }
}