#brand-navigation {
  margin-top: -30vh;
  padding: 15px 50px;
  margin-bottom: 100px;
  .container > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    li > div {
      display: flex;
      align-items: center;
      justify-items: center;
    }
  }
  .clients-table {
    li {
      .paspatout {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #brand-navigation {
    .container > ul {
      flex-direction: column;
      align-items: center;

    }
  }
}
