.contact-form-wrapper {
  position: absolute;
  z-index: 9999;
  padding: 50px;
  background: rgba(0, 0, 0, 0.7);
  left: 30px;
  margin-top: 20px;
  transform: translate(-50%);
  margin-left: 60%;
}

.full-width-form {
  .contact-form-wrapper {
    margin-left: 40vw;
    width: 60%;
  }
}

@media only screen and (max-width: 1024px) {
  .contact-form-wrapper {
    margin-left: 0;
    width: auto;
    transform: translateX(0px);
    padding: 10px;
    left: 0;
    top: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}