@import "mat-theme.scss";

.contact-form-wrapper {
  form {
    margin: 20px 0;
  }
}
.mat-radio-inner-circle {
  height: 20px;
  width: 20px;
  background-color: white;
}

.mat-mdc-form-field {
  width:100%;
  color: white;
}
.mat-mdc-form-field-wrapper {
  width:100%;
}
.mat-mdc-radio-button {
  padding: 0 2em 0 0;
}
.mat-form-field, .mat-form-field-infix, .mat-mdc-form-field-infix {
  width: 100%;
  margin: 10px 0;
}

.contact-server-response {
  .success {
    color: #FFF;
  }
}

/**
 * style the input for autofilled data
 */
 input:-webkit-autofill,
 input:-webkit-autofill:hover, 
 input:-webkit-autofill:focus,
 textarea:-webkit-autofill,
 textarea:-webkit-autofill:hover,
 textarea:-webkit-autofill:focus,
 select:-webkit-autofill,
 select:-webkit-autofill:hover,
 select:-webkit-autofill:focus {
   border: 1px solid #000;
   -webkit-text-fill-color: #FFF;
   font-style: italic;
   -webkit-box-shadow: 0 0 0px 1000px #000 inset;
   transition: background-color 5000s ease-in-out 0s;
   color: #FFF!important;
 }

 .collapse-button, .expand-button {
  cursor:pointer;
  top:8px;
  position:relative;
 }


/**
 * questions to a specific product
 * opens a popup with the contact 
 * form
 */
.mat-dialog-content {
  .close-icon {
    float:right!important;
    z-index: 9999999;
    position: relative;
  }
}
.popup-contact {
  & .contact-form-wrapper {
    position: relative;
    background: #FFF;
    left: inherit;
    transform: none;
    margin-left: 0;
    margin-top:0;
    padding-top: 20px;
  }
  input, textarea {
    color: #000;
  }
  .contact-server-response .success {
    color: #333;
  }
}

.centered-spinner {
  .mat-spinner {
    margin: 10px auto;
  }
}