hr {
  border-bottom: 1px solid #2f2f2f;
}

/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/poppins-v20-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-300italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/poppins-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/poppins-v20-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/poppins-v20-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-600italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/poppins-v20-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/poppins-v20-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/poppins-v20-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/poppins-v20-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/poppins-v20-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* signika-regular - latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/signika-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/signika-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/signika-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/signika-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/signika-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/signika-v20-latin-regular.svg#Signika') format('svg'); /* Legacy iOS */
}
/* bree-serif-regular - latin */
@font-face {
  font-family: 'Bree Serif';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/bree-serif-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/bree-serif-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/bree-serif-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/bree-serif-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/bree-serif-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/bree-serif-v17-latin-regular.svg#BreeSerif') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/MaterialIcons-Regular.ttf');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}