@import "mixins.scss";

/*------------------------------------------------------------------
Project:	Satelite - Creative Showcase Portfolio Template
Version:	1.2
Last change:	19/01/2019
Assigned to:	ClaPat 
Primary use:	Showcase Portfolio 
-------------------------------------------------------------------

@import url("css/showcase.css");
@import url("css/portfolio.css");
@import url("css/shortcodes.css");
@import url("css/assets.css");*/


/*------------------------------------------------------------------

01. General Styles
02. Magic Cursor
03. Page Preloader
04. Header Elements
05. Hero Section
06. Main Content 
07. Footer Elements
08. Responsive Media Querries

-------------------------------------------------------------------*/

	
/*--------------------------------------------------
	01. General Settings
---------------------------------------------------*/		
	
	html,body{
		font-family: 'Poppins', sans-serif;
		font-weight: 300;
		font-size:14px;
		height:100%;
		width:100%;
		color:#777;
		background-color:#000;
	}
	
	html {
		box-sizing: border-box;
		margin-right: 0px!important;
		overflow: visible!important;
	}
	
	body {
		overflow: hidden;
		overflow-y: visible;
		-webkit-transition: opacity 0.2s ease-in-out 0.2s;
		transition: opacity 0.2s ease-in-out 0.2s;
	}
	
	body.smooth-scroll {
		overflow:hidden;
	}
	
	body.hidden {
		opacity:0;
	}

  html.hidden {
    body {
      display: none;
      opacity: 0;
    }
  }
	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
		border: 0;
		margin: 0;
		outline: 0;
		padding: 0;
		vertical-align: baseline;
	}
	
	article,
	aside,
	details,
	figcaption,
	footer,
	header,
	hgroup,
	nav,
	section {
		display: block;
	}
	
	audio,
	 {
		display: inline-block;
		max-width: 100%;
	}	
	
	address {
		font-style: italic;
		margin-bottom: 24px;
	}
	
	abbr[title] {
		border-bottom: 1px dotted #2b2b2b;
		cursor: help;
	}
	
	b,
	strong {
		font-weight: 600;
		color:#000;
	}
	
	.light-content b, .light-content strong {
		font-weight: 600;
		color:#fff;
	}
	
	cite,
	dfn,
	em,
	i {
		font-style: italic;
	}
	
	mark, ins {
		background: none repeat scroll 0 0 #111;
		text-decoration: none;
		color: #fff;
		padding: 0px 10px;
		display: inline-block;
		border-radius: 2px;
	}
	
	code,
	kbd,
	tt,
	var,
	samp,
	pre {
		font-family: monospace, serif;
		font-size: 15px;
		-webkit-hyphens: none;
		-moz-hyphens:    none;
		-ms-hyphens:     none;
		hyphens:         none;
		line-height: 1.6;
	}
	
	pre {
		border: 1px solid rgba(0, 0, 0, 0.1);
		-webkit-box-sizing: border-box;
		-moz-box-sizing:    border-box;
		box-sizing:         border-box;
		margin-bottom: 24px;
		max-width: 100%;
		overflow: auto;
		padding: 12px;
		white-space: pre;
		white-space: pre-wrap;
		word-wrap: break-word;
	}
	
	blockquote,
	q {
		-webkit-hyphens: none;
		-moz-hyphens:    none;
		-ms-hyphens:     none;
		hyphens:         none;
		quotes: none;
	}
	
	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: "";
		content: none;
	}
	
	blockquote {
		color: #000;
		font-size: 18px;
		font-style: italic;
		font-weight: 300;
		line-height: 30px;
		margin-bottom: 24px;
		font-family:Georgia, "Times New Roman", Times, serif;
		border-left:2px #ddd solid;
		padding:20px 20px 20px 40px;
		letter-spacing: 0.01em;
	}
	
	.light-content blockquote {
		color: #fff;
	}
	
	blockquote span {
		display:block;
		margin-top:20px;
		font-weight:400;
		font-size:12px;
		font-style:normal;
		font-family: Verdana,Geneva,sans-serif;
		color:#999;
	}
	
	blockquote cite,
	blockquote small {
		color: #2b2b2b;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
	}
	
	blockquote em,
	blockquote i,
	blockquote cite {
		font-style: normal;
	}
	
	blockquote strong,
	blockquote b {
		font-weight: 400;
	}
	
	small {
		font-size: smaller;
	}
	
	big {
		font-size: 125%;
	}
	
	sup,
	sub {
		font-size: 75%;
		height: 0;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}
	
	sup {
		bottom: 1ex;
	}
	
	sub {
		top: .5ex;
	}
	
	dl {
		margin-bottom: 24px;
	}
	
	dt {
		font-weight: bold;
	}
	
	dd {
		margin-bottom: 24px;
	}
	
	ul,
	ol {
		list-style: none;
		margin: 0 0 24px 20px;
	}
	
	ul,
	ol {
		list-style: none;
		margin: 0 0 24px 20px;
	}
	
	ul {
		list-style: disc;
	}
	
	ol {
		list-style: decimal;
	}
	
	li > ul,
	li > ol {
		margin: 10px 0 0 20px;
	}
	
	li {
		line-height: 20px;
		color:#333;
		margin-bottom:10px;
	}
	
	.light-content li {
		color:#fff;
	}
	
	figure {
		margin:0;
		position:relative;
		display: block;
	}
	
	figure.has-parallax {		
		overflow:hidden;
	}
	
	figure img {
		width:100%;
	}
	
	.full img {
		width:100vw;
	}
	
	figcaption {
		background-color: rgba(0,0,0,1);
		bottom: 25px;
		color: #fff;
		font-weight:400;
		font-size: 12px;
		padding: 10px 20px;
		position: absolute;
		right: 25px;
		z-index: 10;
		border-radius:3px
	}
	
	fieldset {
		border: 1px solid rgba(0, 0, 0, 0.1);
		margin: 0 0 24px;
		padding: 0;
	}
	
	legend {
		white-space: normal;
	}
	
	button,	input {
		line-height: normal;
	}
	
	input,
	textarea {
		background-image: -webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0)); /* Removing the inner shadow, rounded corners on iOS inputs */
	}
	
	button, html input[type="button"], input[type="reset"], input[type="submit"] {
		cursor: pointer;
	}
	
	button[disabled],
	input[disabled] {
		cursor: default;
	}
	
	input[type="checkbox"],
	input[type="radio"] {
		padding: 0;
	}
	
	input[type="search"] {
		-webkit-appearance: textfield;
	}
	
	input[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	
	button::-moz-focus-inner, input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
	
	textarea {
		overflow: auto;
		vertical-align: top;
	}
	
	table, th, td {
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
	
	table, th, td {
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
	
	table {
		border-collapse: separate;
		border-spacing: 0;
		border-width: 1px 0 0 1px;
		margin-bottom: 24px;	
		width: 100%;
	}
	
	caption, th, td {
		font-weight: normal;
		text-align: left;
	}
	
	th {
		border-width: 0 1px 1px 0;
		padding: 10px;
	}
	
	td {
		border-width: 0 1px 1px 0;
		padding: 10px;
	}
	
	del {
		color: #767676;
	}
	
	hr {
		border: 0;
		height: 25px;
		width:100%;
		float:none;
		margin:0;
		display:inline-block;
	}
	
	hr.small {
		height:20px;
	}
	
	hr:after {
		clear: both;
		content: " ";
		display: block;
		height: 0;
		visibility: hidden;
	}
	
	::selection {
		background: #000;
		color: #fff;
		text-shadow: none;
	}
	
	::-moz-selection {
		background: #000;
		color: #fff;
		text-shadow: none;
	}
	
	img {
		border: 0 none;
		max-width: 100%;
		vertical-align: middle;
		height:auto;
	}
		
	h1, h2, h3, h4, h5, h6 {
		font-weight: 600;
		color:#000;
		margin-bottom:10px;
	}
	
	h1.big-title{
		font-size: 80px;
		font-style: normal;
		font-weight: 600;
		line-height: 110px;
		margin-left:-7px;
	}	
	
	h1{
		font-size:48px;
		line-height: 60px;
	}
	
	h2{
		font-size: 36px;
		line-height: 48px;
	}
	
	h3{
		font-size:30px;
		line-height: 40px;
		margin-left:-1px;
	}
	
	h4{
		font-size:24px;
		line-height: 36px;
		margin-left:-1px;	
	}
	
	h5{
		font-size:18px;
		line-height: 28px;
		margin-left:-1px;
	}
	
	h6{
		font-size:14px;
		line-height: 24px;
	}
	
	.light-content h1, .light-content h2, .light-content h3, .light-content h4, .light-content h5, .light-content h6 {
		color: #fff!important;
	}
	
	.light-content p {
		color:rgba(255,255,255,0.8);
	}
	
	.title-has-line {
		position:relative;
	}
	
	.title-has-line::after {
		background: none repeat scroll 0 0 #000;
		bottom: 20px;
		content: "";
		height: 1px;
		left: -40px;
		position: absolute;
		width: 25px;
	}
	
	p.title-has-line::after {
		background: none repeat scroll 0 0 #000;
		top: 14px;
		content: "";
		height: 1px;
		left: -30px;
		position: absolute;
		width: 16px;
	}
	
	.light-content .title-has-line::after {
		background: none repeat scroll 0 0 rgba(255,255,255,1);
	}
	
	p {
		font-size:14px;
		margin-bottom:10px;
		line-height:28px;
		color:#777;
	}
	
	.light-content p {
		color:#999;
	}
	
	p.no-margins {
		margin-bottom:0;
	}
	
	.bigger {
		font-size: 24px;
		font-weight: 400;
		line-height:36px;
		color:#222;
	}
	
	.smaller {
		font-size: 12px;
	}
	
	.container {
		max-width:1280px;
		width:100%;
		margin:0 auto;
		box-sizing:border-box;
	}
	
	.post-content .container {
		padding:0;
	}
	
	.container::after {
		clear: both;
		content: " ";
		display: table;
	}
	
	a:hover, a:active {
	  outline: 0;
	  color: #CCC;
	}
	
	a {
		text-decoration: none;
		color: #FFF;
		outline: 0;
	}
	
	a:hover {
		text-decoration:none;
	}
	
	.one_half {
    	width: 48%!important;
	}	

	.one_third {
		width: 28.8%!important;
	}	

	.one_fourth {
		width: 22%!important;
	}	

	.one_fifth {
		width: 16.8%!important;
	}	

	.one_sixth {
		width: 13.33%!important;
	}	

	.two_fifth {
		width: 37.6%!important;
	}	

	.two_fourth {
		width: 48%!important;
	}		

	.two_third {
		width: 65.33%!important;
	}	

	.three_fifth {
		width: 58.4%!important;
	}	

	.three_fourth {
		width: 74%!important;
	}	

	.four_fifth {
		width: 79.2%!important;
	}	

	.five_sixth {
		width: 82.67%!important;
	}	

	.one_half, .one_third, .two_third, .three_fourth, .one_fourth, .two_fourth, .one_fifth, .two_fifth, .three_fifth, .four_fifth, .one_sixth, .five_sixth {
		float: left!important;
		margin-bottom: 20px!important;
		margin-right: 4%!important;
		position: relative!important;
	}

	.last {
		margin-right:0px!important;
	}
	
	.text-align-center {
		text-align:center;
	}
	.center-items {
		display: flex;
		flex-direction: column;
	}

	.text-align-center {
		li {
      		list-style-type: none;
    	}
	}
	
	.text-align-left {
		text-align:left;
	}
	
	.text-align-right {
		text-align:right;
	}
	
	.outer {
		display:table;
		width:100%;
		height:100%;
	}
	
	.inner {
		display:table-cell;
		vertical-align:middle;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		box-sizing:border-box;
	}
	
	.vc_row {
		position:relative;
		z-index:1;
		opacity:1;
		box-sizing:border-box;
	}
	
	.vc_row.small {
		max-width:800px;
		width:100%;
		margin:0 auto;
	}
	
	.vc_row.full {
		margin-left: calc(50% - 50vw)!important;
		margin-right: calc(50% - 50vw)!important;
		max-width: 1000%!important;
		width: 100vw!important;
	}
	
	.full {
		margin-left: calc(50% - 50vw)!important;
		margin-right: calc(50% - 50vw)!important;
		max-width: 1000%!important;
		width: 100vw!important;
	}
	
	.vc_row::after {
		clear: both;
		content: " ";
		display: table;
	}
	
	.full .vc_column_container > .vc_column-inner {
		padding-left: 0!important;
		padding-right: 0!important;
	}
	
	.full .wpb_button, .full .wpb_content_element, .full ul.wpb_thumbnails-fluid > li {
		margin-bottom: 0!important;
	}
	
	.vc_row.has-animation {
		opacity:0;
	}
	
	.row_padding_top {
		padding-top:8vw;
	}
	
	.row_padding_bottom {
		padding-bottom:8vw;
	}
	
	.row_padding_left {
		padding-left:8vw;
	}
	
	.row_padding_right {
		padding-right:8vw;
	}
	
	.row_padding_all {
		padding:8vw;
	}
	
	main {
		-webkit-transition: background 0.4s ease-in-out 0s;
		transition: background 0.4s ease-in-out 0s;
	}

/*--------------------------------------------------
	02. Magic Cursor
---------------------------------------------------*/	
	
	
	#magic-cursor {
	  position: absolute;
	  left:0;
	  top:0;
	  width: 30px;
	  height: 30px;
	  pointer-events: none;
	  z-index:10000;
	  -webkit-transition: opacity 0.2s ease-in-out 0.5s;
		transition: opacity 0.2s ease-in-out 0.5s;
	}
	
	.hidden-ball #magic-cursor {
		opacity:0!important;
	}
	
	#ball {
		position: fixed;
		transform: translate(-50%, -50%);
		width: 30px;
		height: 30px;
		border: 2px solid #000;
		border-radius: 50%;
		pointer-events: none;
		opacity:1;
		box-sizing:border-box;	
	}
	
	.mfp-zoom-out-cur #ball {
		opacity:0;
		-webkit-transition: opacity 0.2s ease-in-out 0s;
		transition: opacity 0.2s ease-in-out 0s;
	}
	
	.light-content #ball, #ball.over-movie,  #ball.with-icon {
		border: 2px solid #fff;
		border-color:#fff!important;
	}
	
	#ball:before {
		font-family: FontAwesome;
		content: "\f053";
		font-size:6px;
		width:4px;
		height:8px;
		line-height:8px;
		text-align:center;
		position:absolute;
		left:-12px;
		top:9px;
		color:#000;
		opacity:0;
		transition: all 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball:before {
		color:#fff;
	}
	
	#ball:after {
		font-family: FontAwesome;
		content: "\f054";
		font-size:6px;
		width:4px;
		height:8px;
		line-height:8px;
		text-align:center;
		position:absolute;
		right:-10px;
		top:9px;
		color:#000;
		opacity:0;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball:before, .light-content #ball:after {
		color:#fff;
	}
	
	.scale-up #ball:before, .scale-up #ball:after {
		opacity:1;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.scale-up.scale-none #ball:before, .scale-up.scale-none #ball:after {
		opacity:0;
	}
	
	#hold-event {
		width: 26px;
		height: 26px;
		position: absolute;
		top: 0px;
		left: 0px;
		border-radius: 100%;
		background: rgba(255,255,255,0);
	}
	
	#hold-event::after {
		content: "Hold";
		font-size: 11px;
		font-weight:500;
		width: 60px;
		height: 10px;
		box-sizing:border-box;
		line-height: 10px;
		padding-top:10px;
		text-align: center;
		position: absolute;
		right: -18px;
		top: 45px;
		color: #000;
		opacity:0;
		overflow:hidden;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #hold-event::after {
		color: #fff;
	}
	
	.hold #hold-event::after {		
		padding-top:0;
		opacity:1;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
	}
	
	.with-icon #hold-event {
		opacity:0;
		padding-top:20px;
		transition: all 0s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.with-icon #hold-event:after {
		opacity:0;
		padding-top:20px;
		transition: all 0s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	#ball i {
		color:#000;
		width:29px;
		height:30px;
		line-height:28px;
		text-align:center;
		font-size:6px;
		display:block;
		opacity:1;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball i, #ball.over-movie i, #ball.with-icon i {
		color:#fff;
	}
	
	.scale-up #ball i {
		opacity:0;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	#ball.with-icon i {
		width:27px;
	}
	
	#ball.with-icon i:nth-child(2) {
		display:none;
	}
	
	#ball.close-icon i {
		width:27px;
		font-size:8px;
	}
	
	#ball.over-movie i.fa-play, #ball.over-movie.pause-movie i.fa-pause {
		display:block;
	}
	
	#ball.over-movie i.fa-pause, #ball.over-movie.pause-movie i.fa-play {
		display:none;
	}
	
	#ball-loader {
		width: 40px;
		height: 40px;
		position:absolute;
		background-color: transparent;
		border-right: 2px solid transparent;
		border-bottom: 2px solid #000;
		border-left: 2px solid transparent;
		border-top: 2px solid transparent;
		border-radius: 50px;
		box-sizing: border-box;
		opacity:0;
		transform: translate(-9px, -9px) rotate(0deg);
		-webkit-animation: rotating 0.8s ease-in-out infinite;
		animation: rotating 0.8s ease-in-out infinite;
		-webkit-transition: opacity 0s ease-in-out 0s;
		transition: opacity 0s ease-in-out 0s;
	}
	
	.light-content #ball-loader { 
		border-bottom: 2px solid #fff;
	}
	
	.show-loader #ball-loader {
		opacity:1;
		-webkit-transition: opacity 0.2s ease-in-out 0s;
		transition: opacity 0.2s ease-in-out 0s;
	}
	
	@keyframes rotating {
      0% {
		-webkit-transform: translate(-7px, -7px) rotate(0deg);
		transform:  translate(-7px, -7px) rotate(0deg);
      }
	  
	  95% {
        -webkit-transform:  translate(-7px, -7px) rotate(350deg);
		transform:  translate(-7px, -7px) rotate(350deg);
      }
	  
      100% {
        -webkit-transform:  translate(-7px, -7px) rotate(360deg);
		transform:  translate(-7px, -7px) rotate(360deg);
      }
	}
	
	.show-loader a, .show-loader nav {
		pointer-events:none;
	}
	
	#rotate-device {
		width:100%;
		height:100%;
		position:fixed;
		z-index:1000;
		top:0;
		left:0;
		background-color:#0f1010;
		background-image:url(../assets/rotate.png);
		background-size:100px 100px;
		background-position:center;
		background-repeat:no-repeat;
		display:none;
	}
	
	
/*--------------------------------------------------
	03. Page Preloader
---------------------------------------------------*/	

	.preloader-wrap {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0; 
		bottom: 0;
		background: #fff;
		z-index : 800;
		text-align:center;
	}
	
	.light-content.preloader-wrap {
		background: #000;
	}
	
	.percentage {
		z-index: 100;
		color: #000;
		opacity:1;
		font-weight: 700;
		font-size: 3.5vw;
		line-height: 90px;
		color:rgba(0,0,0,1);
	}
	
	.light-content .percentage {
		color:rgba(255,255,255,1);
	}
	
	.trackbar {
		width: 30vw;
		height: 1px;
		background: rgba(0,0,0,0.2);
		margin: 0 auto;
		margin-top:70px;
		position: relative;
		left:0;
		right:0;
		opacity: 1;
	}
	
	.light-content .trackbar {
		background: rgba(255,255,255,0.2);
	}
	
	.loadbar {
		width: 0%;
		height: 1px;
		background: rgba(0,0,0,1); 
		position: absolute;
		top: 0px;
		left: 0;
		right:0;
		margin: 0 auto;
		overflow: hidden;
	}
	
	.light-content .loadbar {
		background: rgba(255,255,255,1);
	}
	
	.hold-progress-bar {
		width: 0%;
		height: 3px;
		background: rgba(255,255,255,1); 
		position: absolute;
		top: 0px;
		left: 0;
		right:0;
		margin: 0 auto;
		overflow: hidden;
	}
	
	.light-content .hold-progress-bar {
		background: rgba(0,0,0,1);
	}
	
	.headphones {
		position:relative;
		text-align:center;
		line-height:50px;
		width:50px;
		height:50px;
		font-size:40px;
		margin:0 auto;
		margin-top:80px;
		color:#fff;
		background-image:url(../assets/headphones.png);
		background-size:50px 50px;
		background-position:center center;
	}
	
	.light-content .headphones {
		background-image:url(../assets/headphones-white.png);
	}
	
	.headphones:before {
		content:"(";
		position:absolute;
		height:10px;
		width:4px;
		top:32px;
		left:-6px;
		line-height:10px;
		font-size:10px;
		font-weight:700;
		color:#fff;
		-webkit-animation: movebefore 0.8s ease-out infinite;
		animation: movebefore 0.8s ease-out infinite;
	}
	
	@keyframes movebefore {
      0% {
		left:-6px;
		opacity:1;
      }
	  
	  100% {
        left:-12px;
		opacity:0
      }
	}
	
	.headphones:after {
		content:")";
		position:absolute;
		height:10px;
		width:4px;
		top:32px;
		right:-6px;
		line-height:10px;
		font-size:10px;
		font-weight:700;
		color:#fff;
		-webkit-animation: moveafter 0.8s ease-out infinite;
		animation: moveafter 0.8s ease-out infinite;
	}
	
	@keyframes moveafter {
      0% {
		right:-6px;
		opacity:1;
      }
	  
	  100% {
        right:-12px;
		opacity:0
      }
	}
	
	.headphones-text{
		font-size:10px;
		margin-top:20px;
	}
	
/*--------------------------------------------------
	04. Header Elements
---------------------------------------------------*/	
	
	.admin-bar header {
		top:32px;
	}
	
	header {
		width:100%;
		height:140px;
		left:0;
		top:0;
		background-color:transparent;
		position:fixed;
		box-sizing:border-box;
		z-index:1000;
		pointer-events:none;
		-webkit-transition: background 0.4s ease-in-out 0.3s;
		transition: background 0.4s ease-in-out 0.3s;
	}
	
	#header-container {
		box-sizing: border-box;
		height: inherit;
		padding: 30px 80px;
		margin: 0 auto;
		position: relative;
		width: 100%;
		z-index: 20;
		opacity: 0;
	}
	
	.header-visible #header-container{
		opacity:1;
	}
	
	#logo {
		position: relative;
		display: table;
		pointer-events: initial;
		z-index: 10;
		top: 20px;
		float: left;
	}
	
	#logo a {
		display:block;
		-webkit-transition: transform 0.5s ease-in-out 0.1s;
		transition: transform 0.5s ease-in-out 0.1s;	
	}
	
	.logo-hidden #logo a {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
	
	#logo a.disable, .open #logo a {
		pointer-events: none;
	}
	
	#logo img {
		display: block;
		height: 40px;
		width: auto;
		max-width:none;
	}
	
	#logo img.black-logo {
		opacity:1;
	}
	
	.light-content #logo img.black-logo {
		opacity:0;
	}
	
	#logo img.white-logo {
		position:absolute;
		top:0;
		left:0;
		opacity:0;
	}
	
	.light-content #logo img.white-logo {
		opacity:1;
	}
	
	.menu-open.light-content .slide-in #logo img.white-logo {
		opacity:1;
	}
	
	nav {
		pointer-events: initial;
	}

  .classic-menu {
    background: -moz-linear-gradient(top, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 95%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 95%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 95%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
  }
	
	@media all and (min-width: 1025px) {
	
		.classic-menu nav {
			position: relative;
			width: auto;
			top: 0px;
			-webkit-transition: all 0.2s ease-in-out 0.5s;
			transition: all 0.2s ease-in-out 0.5s;
			display:block;
			float:right;
			height: 60px;
			margin: 10px 0;
			background-color:transparent!important;
		}
		
		.flexnav {
			display: block;
			float: right;
			position: relative;
			width: auto;
			max-height: 60px;
		}
		
		.flexnav li ul {   
			min-width: 170px;
		}	
		
		.flexnav .touch-button {
			background: transparent none repeat scroll 0 0;
		}	
		
		.flexnav .touch-button .navicon {
			display:none;
		}
		
		.flexnav li {    
			background: transparent none repeat scroll 0 0;
			padding:0 25px;
			padding-bottom:20px;
			padding-top:20px;
		}
		
		.classic-menu .menu-timeline {
			opacity:1!important;
			-webkit-transition: translateY(0px)!important;
			transform: translateY(0px)!important;
			span {
			}
		}
		
		.flexnav li:last-child {
			padding-right:0px;
		}
		
		.flexnav li a {    
			background: transparent none repeat scroll 0 0;
			padding: 0 5px;
			position:relative;
			border-left: medium none;
			font-weight: 600;
			font-family: 'Poppins', sans-serif;
			color:#000;		
			font-size:12px;
			line-height:20px;
			display:block;
			overflow:hidden;
			-webkit-transition: all 0.15s ease-in-out;
			transition: all 0.15s ease-in-out;
		}
		
		.light-content .flexnav li a { 
			color:#fff;		
		}
		
		.flexnav:hover li a {
			color: rgba(0,0,0,0.4);
		}
		
		.light-content .flexnav:hover li a {
			color: rgba(255,255,255,0.6);
		}
		
		.flexnav li:hover a {
			color:#000;
		}
		
		.light-content .flexnav li:hover a {
			color:#fff;
		}
	
		.classic-menu .flexnav li a span {
			position: relative;
			display: block;
			-webkit-transition: -webkit-transform 0.2s;
			transition: transform 0.2s;
			transform-origin: 100% 0%;
		}
		
		.classic-menu .flexnav li a span::before {
			position: absolute;
			top: 100%;
			width:100%;
			left:0;
			content: attr(data-hover);
		}
		
		.classic-menu .flexnav li:hover a span {
			-webkit-transform: translateY(-100%);
			transform: translateY(-100%);
			transform-origin: 0% 0%;
		}
		
		.flexnav li ul li a { 
			padding:0 20px 20px;
			background-color:transparent;
			font-size:13px;
			font-family: 'Roboto', sans-serif;
			font-weight:400;
			text-transform:none;
			color:#aaa!important;
			-webkit-transition: all 0.05s ease-in-out;
			-moz-transition: all 0.05s ease-in-out;
			-o-transition: all 0.05s ease-in-out;
			-ms-transition: all 0.05s ease-in-out;
			transition: all 0.05s ease-in-out;
		}
		
		.flexnav li ul li a.link::before {
			display:none;
		}
		
		.flexnav li ul li a.active, .flexnav li ul li a:hover {
			color:#fff!important;
		}
		
		.flexnav li > ul li {
			margin-left:0;
			padding:0;
		}
		
		.classic-menu .flexnav li ul {
			-webkit-transform: translate3d(0px, 30px, 0px);
			transform: translate3d(0px, 30px, 0px);
			display:block!important;
			opacity:0!important;
			height:inherit!important;
			overflow:visible!important;
			visibility:hidden;
			left:10px;
			top:60px;
			padding-top:20px;
			background: #FFF;		
			border-radius:3px;
			-webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out!important;
			transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out!important;
		}
		
		.flexnav li ul ul {
			left:5px!important;
		}
		
		.flexnav li ul:after {
			display: block;
			content: '';
			position: absolute;
			top: -6px;
			left: 20px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 9px;
			border-color: transparent transparent #000;
		}
		
		.flexnav ul li ul:after {
			display: block;
			content: '';
			position: absolute;
			top: 15px;
			left: -10px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 9px;
			border-color: transparent transparent #000;
			-webkit-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}
		
		.flexnav ul li ul li a {
			background: #000;
		}
		
		.flexnav li ul.flexnav-show {
			-webkit-transform: translate3d(0px, 0px, 0px);
			transform: translate3d(0px, 0px, 0px);
			opacity:1!important;
			visibility:visible;
			-webkit-transition: opacity 0.2s ease-in-out 0.1s, visibility 0.2s ease-in-out, transform 0.2s ease-in-out 0.1s;
			transition: opacity 0.2s ease-in-out 0.1s, visibility 0.2s ease-in-out, transform 0.2s ease-in-out 0.1s;	
		}
		
		.flexnav .touch-button {
			display:none;
		}		
		
		/*-- Full Screen Menu --*/	
		
		.fullscreen-menu .nav-height {
			overflow-y: scroll;
			position: relative;
			height: 100%;
			padding: 40px 0;
			padding-right: 30px;
			width: calc(100% + 30px);
			box-sizing: border-box;
		}
		
		.fullscreen-menu nav {
			height: 100vh;
			position: fixed;
			top:0;
			left:0;
			width: 100%;
			box-sizing: border-box;
			visibility: visible;
			pointer-events:none;
			opacity:0;
			padding:20px 0;		
			-webkit-transition: all 0.2s ease-in 0.6s;
			transition: all 0.2s ease-in 0.6s;
		}
		
		.fullscreen-menu nav.open {
			visibility:visible;
			pointer-events:initial;
			opacity:1;
			-webkit-transition: all 0.2s ease-out;
			transition: all 0.2s ease-out;
		}
		
		.fullscreen-menu .flexnav {
			max-height: 2000px;
			-webkit-transition: all 0.2s ease-in 0.6s;
			transition: all 0.2s ease-in 0.6s;
			display: table;
			width: auto;
			margin: 0 auto;
			float: none;
		}
		
		.fullscreen-menu .flexnav.flexnav-show {
			-webkit-transition: all .3s ease-out 0.2s;
			transition: all .3s ease-out 0.2s;	
		}
		
		.fullscreen-menu .flexnav li {
			text-align:center;
			line-height: 90px;
			padding:0 25px;
			box-sizing:border-box;
			float:none;
			-webkit-transition: color .15s ease-out 0s;
			transition: color .15s ease-out 0s;	
		}

		.flexnav {
			li.logout-item {
				padding-top: 12px!important;
				button {
					font-size: 11px;
					font-family: "Poppins", sans-serif;
				}
			}
		}
		
		.fullscreen-menu .menu-timeline {
			opacity:0;
			-webkit-transition: translateY(80px);
			transform: translateY(80px);
		}
		
		.fullscreen-menu .flexnav:hover li  {
			color:rgba(255,255,255,0.4)
		}
		
		.fullscreen-menu .flexnav li:hover, .fullscreen-menu .flexnav li.active {
			color:rgba(255,255,255,1)
		}
		
		.fullscreen-menu .flexnav .touch-button {
			width: 100%;
			display: block;
			height: calc(4vw + 20px);
		}
		
		.fullscreen-menu .flexnav .touch-button .navicon {
			display:none;
		}
		
		.fullscreen-menu .flexnav li.link {
			border-bottom: none;	
		}
		
		.fullscreen-menu .flexnav li a {
			font-weight: 700;
			font-family: 'Poppins', sans-serif;
			font-size: 3.5vw;
			line-height:90px;
			padding:0;
			color:inherit;
			text-transform:uppercase;
		}
		
		.fullscreen-menu .flexnav a.link::before {
			display:none;
		}
		
		.light-content .fullscreen-menu .flexnav li a {
			color: inherit;
		}
		
		.fullscreen-menu .flexnav li ul {
			margin-bottom:20px;
			position:relative;
			left:0;			
		}
		
		.fullscreen-menu .flexnav li ul:after {
			display:none;
		}
		
		.fullscreen-menu .flexnav li ul li a {
			padding: 10px 0;
			font-size: 16px;
			font-weight: 500;
			line-height:20px;
			border-top: none;
			text-transform:none;
		}
		
		.fullscreen-menu li.buy-item  {
			position: fixed !important;
			top: calc(100vh - 100px);
			font-size: 14px;
			line-height:30px;
			font-weight: 500;
			margin: 0 auto;
			display: table !important;
			width: 160px;
			left: calc(50% - 90px);
			pointer-events: initial;
		}
		
		.fullscreen-menu li.buy-item a {			
			font-size: 14px;
			line-height:30px;
			pointer-events: initial;
		}
	
	}

	nav, nav.open {
		-webkit-transition: all 0s ease-in 0s;
		transition: all 0s ease-in 0s;
		background:transparent;
	}

	#burger-wrapper {
		width: 80px;
		height: 80px;
		float: right;
		top: 0px;
		right: -25px;
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		pointer-events: initial;
	}
	
	.classic-menu #burger-wrapper {
		display:none;
	}

	#menu-burger {
		width: 16px;
		height: 22px;
		position: relative;
		margin: 0 auto;		
		z-index:2;
		pointer-events: none;
	}
	
	.menu-overlay #menu-burger {
		display:block;
	}

	#menu-burger span {
	  display: block;
	  position: absolute;
	  height: 2px;
	  width: 100%;
	  background-color: #000;
	  opacity: 1;
	  right: 0;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: background-color  0.05s ease-in-out,  transform  0.2s ease-in-out,  top  0.2s ease-in-out;
		transition: background-color  0.05s ease-in-out,  transform  0.2s ease-in-out,  top  0.2s ease-in-out;
	}
	
	#burger-wrapper .touch-button {
		display:none;
	}
	
	.light-content #menu-burger span {
		background-color:#fff;
	}

	#menu-burger span:nth-child(1) {
	  top: 7px;
	}
	
	#menu-burger span:nth-child(2){
	  top: 15px;
	}
	
	#menu-burger.open span:nth-child(1) {
	  -webkit-transform: rotate(45deg);
	  -moz-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
	  transform: rotate(45deg);
	  top:10px;
	}
	
	#menu-burger.open span:nth-child(2) {
	  -webkit-transform: rotate(-45deg);
	  -moz-transform: rotate(-45deg);
	  -o-transform: rotate(-45deg);
	  transform: rotate(-45deg);
	  top:10px;
	}
	
	
	
	
/*--------------------------------------------------
	05. Hero Section
---------------------------------------------------*/		
	
	#hero {		
		width:100%;
		height:auto;
		max-height:100%;
		position:relative;
		z-index:0;
	}
	
	#hero.has-image {
		z-index:2;
		height:50vh;
		overflow:hidden;
	}
	
	#hero.error {
		height:calc(100vh - 140px);
	}
	
	#hero-styles {
		position: relative;
		width: 100%;
		height:auto;
		top: 0;
		left: 0;
		right: 0;
		display: block;
		margin: 0 auto;
		z-index:2;		
	}
	
	#hero.has-image #hero-styles, #hero.error #hero-styles {
		position: fixed;
		height:70vh;
		transform: translateY(-30vh);
		-webkit-transform: translateY(-30vh);
	}
	
	#hero-caption {
		display: table;
		width: 100%;
		max-width:1280px;
		padding: 200px 30px 20px 30px;
		margin: 0 auto;
		height: 100%;
		position: relative;
		box-sizing: border-box;		
	}
	
	#hero.has-image #hero-caption {
		text-align:center;
		padding: 0 30px;
	}
	
	#hero #hero-caption .inner {
		vertical-align: bottom;
	}
	
	#hero-bg-wrapper {
		position: fixed;
		width: 100%;
		height: 50vh;
		z-index: 1;
		margin: 0 auto;
		left: 0;
		top:0;
		right: 0;
		-webkit-transition: filter 0.6s ease-in-out;
		transition: filter 0.6s ease-in-out;
		overflow: hidden;
		opacity: 0.5;
	}
	
	#hero-bg-image::after {
		content: "";
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
	}
	
	#hero-image-parallax {
		position:absolute;
		width:100%;
		height:100%;
	}
	
	#hero-bg-image {
		background-size:cover;
		position:absolute;
		background-position:center center;
		width:100%;
		height:100%;
		z-index:0;
		opacity:0;
		perspective: 1000px;
		overflow:visible;
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
	
	.load-project-page #hero-bg-image, .load-next-project #hero-bg-image {
		opacity:1;
		-webkit-transform: scale(1.05);
		transform: scale(1.05);	
	}
	
	.hero-title {
		font-weight: 700;
		font-family: 'Poppins', sans-serif;
		display:inline-block;
		vertical-align: top;
		margin:0 auto;
		margin-bottom:0px;
		width:auto;
		font-size:3.5vw;
		line-height:90px;
		color:#000;		
		position:relative;
		transform: translateY(5vh);
		-webkit-transform: translateY(5vh);
		opacity:0;
		text-transform: uppercase
	}
	
	.light-content .hero-title {
		color:#fff;		
	}
	
	.load-project-page .hero-title, .load-next-project .hero-title  {
		transform: translateY(-90px)!important;
		-webkit-transform: translateY(-90px)!important;
		opacity:1!important;
	}
	
	.load-next-page .hero-title {
		transform: translateY(-20px)!important;
		-webkit-transform: translateY(-20px)!important;
		opacity:1!important;
	}
	
	.text-align-center .hero-title, #hero.has-image .hero-title {
		text-align:center;
	}
	
	.hero-title span {
		display: block;
		float:left;
		margin: 0;
		min-width: 20px;
		width:auto;
		line-height:90px;
		height:90px;
		box-sizing: border-box;
	}
	
	.hero-subtitle {
		font-size:14px;
		font-weight:600;
		line-height:20px;
		margin-bottom:0px;
		width: 100%;
		z-index: 10;
		opacity:0;
		position:relative;
		color:#000;
		transform: translateY(15vh);
		-webkit-transform: translateY(15vh);
	}
	
	.load-project-page .hero-subtitle, .load-next-project .hero-subtitle {
		transform: translateY(20px)!important;
		-webkit-transform: translateY(20px)!important;
		opacity:1!important;
	}
	
	.load-next-page .hero-subtitle {
		transform: translateY(90px)!important;
		-webkit-transform: translateY(90px)!important;
		opacity:1!important;
	}
	
	.light-content .hero-subtitle {
		color:#fff;		
	}
	
	.text-align-center .hero-subtitle, #hero.has-image .hero-subtitle{
		text-align:center;
	}
	
	#hero.has-image .hero-subtitle:before, .text-align-center .hero-subtitle:before {
		left:50%;
		margin-left:-10px;
	}
	
/*--------------------------------------------------
	06. Main Content
---------------------------------------------------*/		
	
	.smooth-scroll #content-scroll {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100vw;
		z-index:10;
		overflow-y: scroll;
		overflow-x: hidden;
		scroll-behavior: inherit;
	}
	
	.scrollbar-track {
		z-index:100;
		background: transparent!important;
	}
	
	.scrollbar-thumb {
		background: rgba(255, 255, 255, .5)!important;
	}

	#main {
		position:relative;
		opacity:0;
	}
	
	.load-project-page #main, .load-next-project #main, .load-next-page #main {
		opacity:1;
	}
	
	#main-content {
		position:relative;
		opacity:1;
		z-index:10;		
		-webkit-transition: all 0.4s ease-in-out 0s;
		transition: all 0.4s ease-in-out 0s;
	}
	
	#main-page-content {
		position: relative;
		box-sizing:border-box;
		padding:0px;
		opacity: 1;
		max-width:1280px;
		margin:0 auto;
		margin-bottom:200px;
		transform: translateY(15vh);
		-webkit-transform: translateY(15vh);
	}
	
	#main-page-content::after {
		clear: both;
		content: " ";
		display: table;
	}
	
	#main-page-content.project-page {
		margin-bottom: calc(45vh - 160px);
	}
	
	#main-page-content.portfolio-page {
		width:100%;
		max-width:none;
		padding:0;
	}
	
	#temporary-wrapper {
		display:none;
		visibility:hidden;
		opacity:0;
	}
	
/*--------------------------------------------------
	07. Page Navigation
---------------------------------------------------*/	
	
	#page-nav {
		height: auto;
		width: 100%;
		color: #000;
		position: relative;
		margin-bottom: 0;
	}
	
	.light-content #page-nav {
		color: #fff;
	}
	
	.next-page-wrap {
		position: relative;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
	}
	
	
	.next-page-title {
		display: table;
		width: auto;
		padding: 100px 30px 20px 30px;
		margin: 0 auto;
		height: 100%;
		position: relative;
		box-sizing: border-box;
	}
	
	.next-page-title .inner {
		vertical-align: bottom;
		text-align:left;
		height:110px;
	}
	
	.next-page-title .inner.text-align-center {
		vertical-align: bottom;
		text-align:center;
	}

	.page-title {
		font-weight: 700;
		font-family: 'Poppins', sans-serif;
		position: relative;
		display:block;
		margin-bottom:0px;
		display:inline-block;
		vertical-align: top;
		width:auto;
		font-size:3.5vw;
		line-height:90px;
	}
	
	.page-title span {
		display: block;
		float:left;
		margin: 0;
		min-width: 20px;
		width:auto;
		line-height:90px;
		height:90px;
		box-sizing: border-box;
		color:transparent;
		-webkit-text-stroke: 1px #000;
	}
	
	.page-title:hover span, .page-is-changing .page-title span {
		color:#000;
		-webkit-text-stroke: 1px transparent;
	}
	
	.light-content .page-title {
		color:transparent;
	}
	
	.light-content .page-title span {
		color:transparent;
		-webkit-text-stroke: 1px #fff;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}
	
	.light-content .page-title:hover span, .page-is-changing .light-content .page-title span {
		color:#fff;
		-webkit-text-stroke: 1px transparent;
	}
	
	.page-subtitle {
		margin-bottom: 0;
		line-height: 20px;
		height:20px;
		overflow:hidden;
		position: relative;
		left: 0;
		display: block;
		font-size:14px;
		font-weight: 600;
		font-family: 'Poppins', sans-serif;
	}
	
	.subtitle-name {
		transform: translateY(-20px);
		-webkit-transform: translateY(-20px);
		opacity:0;
	}
	
	.light-content .page-subtitle {
		color: #fff;
	}
	
	
/*--------------------------------------------------
	07. Footer Elements
---------------------------------------------------*/		
	
	footer {
		position:relative;
		width:250px;
		height:160px;
		z-index:900;
		box-sizing:border-box;
		text-align:center;
		bottom:0;
		right:0;
		overflow:hidden;
	}
	
	footer.fixed {
		position:fixed;
	}
	
	#footer-container {
		padding: 0 80px;
		margin: 40px auto;
		height: 80px;
		opacity: 1;
		position: absolute;
		width: 100%;
		box-sizing: border-box;
		bottom: 0;
		left: 0;
	}
	
	#counter-wrap {
		line-height: 30px;
		position: relative;
		width: 20px;
		margin: 0;
		text-align: center;
		bottom: 0;
		display: inline-block;
		height: 40px;
		line-height:40px;
		float: left;
		color:#000;
		font-size:12px;
		font-weight:600;
	}
	
	.light-content #counter-wrap {
		color:#fff;
	}
	
	#counter-wrap span:first-child {
		opacity:1;
	}
	
	#counter-wrap span {
		position:absolute;
		left:0;
		top:0;
		opacity:0;
	}
	
	#counter-wrap::before {
		position: absolute;
		width:20px;
		top: 0;
		left:100px;
		content: attr(data-hover);
	}
	
	#counter-wrap:after {
		width: 40px;;
		height: 1px;
		position: absolute;
		content: "";
		background-color: rgba(0,0,0,0.2);
		left: 40px;
		bottom: 20px;
		-webkit-transition: opacity 0.2s ease-in-out 0.2s;
		transition: opacity 0.2s ease-in-out 0.2s;
	}
	
	.light-content #counter-wrap:after {
		background-color: rgba(255,255,255,0.3);
	}
	
	.copyright-wrap {
		position:relative;
		float: left;
		color: #000;
		width: 270px;
		height: 80px;
		box-sizing: border-box;
		pointer-events:initial;	
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content .copyright-wrap {
		color: #fff;
	}
	
	.copyright-wrap:hover {
		transform: translateY(-50px);
		-webkit-transform: translateY(-50px);
	}
	
	.copyright-text {
		float: left;
		font-size: 12px;
		font-weight: 600;
		font-family: 'Poppins', sans-serif;
		line-height: 40px;
	}
	
	.copyright-icon {
		float: left;
		width: 30px;
		height: 40px;
		font-size: 12px;
		line-height: 40px;
		text-align: left;
		margin-right: 0px;
		transform: scale(1);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.copyright-icon i{
		font-size:13px;
	}
	
	.copyright-wrap:hover .copyright-icon {
		transform:scale(0);
		width:0;
		margin:0;
		overflow:hidden;
		
	}
	
	.copyright-icon:after, .copyright-text:after {
	  content: "";
	  clear: both;
	  display: table;
	}
	
	.copyright {
		height: auto;
		line-height: 30px;
		position: relative;
		width: auto;
		margin:0 auto;
		margin-top: 10px;
		text-align: center;
		bottom: 0;
		display: table;
	}
	
	.copyright-wrap .copyright {
		display: inline-block;
		width: 100%;
		text-align: left;
	}
	
	.copyright p {
		color: #000;
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 0;
		line-height: 30px;
		box-sizing:border-box;
		padding-top:5px;
		position: relative;		
		height: 40px;
		float: left;
		z-index: 2;
		-webkit-transition: background 0.4s ease-in-out 0s;
		transition: background 0.4s ease-in-out 0s;
	}
	
	.light-content .copyright p {
		color: #fff;
	}
	
	.copyright-wrap .copyright p {
		opacity:0;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.copyright-wrap:hover .copyright p {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
		opacity:1;
		transform: translateY(0px);
		-webkit-transform: translateY(0px);		
	}
	
	.socials-wrap {
		position:relative;
		float: right;
		color: #000;
		width: 240px;
		height: 160px;
		box-sizing: border-box;
		pointer-events:initial;	
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content .socials-wrap {
		color: #fff;
	}
	
	footer .socials-wrap {
		margin-top:0px;
	}
	
	.socials-wrap:hover {
		transform: translateY(-40px);
		-webkit-transform: translateY(-40px);
	}
	
	.socials-text {
		float:right;
		font-size:12px;
		font-weight: 600;
		font-family: 'Poppins', sans-serif;
		line-height:40px;
		transform:translateY(20px);
	}
	
	.socials-wrap:hover .socials-text {
		transform: translateY(10px);
		-webkit-transform: translateY(10px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;		
	}
	
	.socials-icon {
		float:right;
		width:30px;
		height:40px;
		font-size:12px;
		line-height:40px;
		text-align:right;
		margin-left:10px;
		transform:scale(1) translateY(20px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.socials-wrap:hover .socials-icon {
		transform:scale(0) translateY(40px);
		width:0;
		margin:0;
		overflow:hidden;
		
	}
	
	.socials-icon:after, .socials-text:after {
	  content: "";
	  clear: both;
	  display: table;
	}
	
	.socials {
		height: auto;
		line-height: 30px;
		position: relative;
		width: auto;
		margin:0 auto;
		margin-top: 10px;
		text-align: center;
		bottom: 0;
		display: table;
		transform: translateX(15px);
		-webkit-transform: translateX(15px);
	}
	
	.socials-wrap .socials {
		display: inline-block;
		width: 100%;
		text-align: right;
	}
	
	.socials li {
		margin-right: 0px;
		margin-left: 10px;
		list-style: none;
		color: #999;
		font-size: 15px;
		margin-bottom: 0;
		line-height: 40px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		float: right;
		z-index: 2;
		-webkit-transition: background 0.4s ease-in-out 0s;
		transition: background 0.4s ease-in-out 0s;
	}
	
	.socials-wrap .socials li {
		opacity:0;
		transform: translateY(20px);
		-webkit-transform: translateY(20px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.socials-wrap:hover .socials li:nth-child(5) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.05s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);		
	}
	
	.socials-wrap:hover .socials li:nth-child(4) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);	
	}
	
	.socials-wrap:hover .socials li:nth-child(3) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);		
	}
	
	.socials-wrap:hover .socials li:nth-child(2) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);	
	}
	
	.socials-wrap:hover .socials li:nth-child(1) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);		
	}
		
	.socials li:last-child {
		margin-left: 0px;
	}
	
	.socials li a {
		color: #000;		
		font-size:12px;
		font-weight: 600;
		display: block;
		height: 40px;
		line-height:40px;
		text-align:center;
		-webkit-transition: opacity 0.2s ease-in-out;
		transition: opacity 0.2s ease-in-out;
	}
	
	.socials:hover li  a {
		opacity:0.3;
	}
	
	.socials li a:hover {
		color: #000;
		opacity:1;
	}
	
	.light-content .socials li a {
		color: #fff;	
	}
	
	.light-content .socials:hover li  a {
		opacity:0.3;
	}
	
	.light-content .socials li a:hover {
		color: #fff;
		opacity:1;
	}
	
	nav, nav.open {
		-webkit-transition: all 0s ease-in 0s;
		transition: all 0s ease-in 0s;
		background:transparent;
	}
	
	
	
/*--------------------------------------------------
	08. Responsive
---------------------------------------------------*/			
			
@media only screen and (max-width: 1466px) {
	
	.percentage {
    	font-size: 4.5vw;
	}
	
	.trackbar {
    	margin-top: 40px;
		width: 45vw;
	}
	
	.headphones {
    	margin-top: 50px;
	}
	
	header {
    	height: 120px;
	}
	
	#header-container {
		padding: 15px 50px;
	}
	
	.flexnav li {
    	padding: 20px;
	}

	@media all and (min-width: 1025px) {	
	
	.fullscreen-menu .flexnav li a {
		font-size: 4.5vw;
		line-height: 70px;
	}
	
	}
	
	.fullscreen-menu li.buy-item a {
		font-size: 14px;
		line-height: 30px;
	}
	
	#main-page-content.project-page {
		margin-bottom: calc(45vh - 120px);
	}
	
	#hero-caption {
    	padding: 160px 30px 20px 30px;
	}
	
	.hero-title, .page-title {
    	font-size: 4.5vw;
		line-height: 70px;
	}
	
	.hero-title span, .page-title span {
		line-height: 70px;
		height: 70px;
	}

	.hero-fade-out-bottom {
		height: 80px;
		width:100%;
		background: linear-gradient(180deg, rgba(26, 29, 24, 0) 6%, rgba(0,0,0,1) 34%, rgba(0,0,0,1) 100%);
		bottom:0;
		position:absolute;
		z-index:30;
	}
	
	.load-project-page .hero-title, .load-next-project .hero-title  {
		transform: translateY(-70px)!important;
		-webkit-transform: translateY(-70px)!important;
		opacity:1!important;
	}
	
	.hero-subtitle {
		transform: translateY(25vh);
		-webkit-transform: translateY(25vh);
	}
	
	.load-next-page .hero-subtitle {
		transform: translateY(70px)!important;
		-webkit-transform: translateY(70px)!important;
		opacity:1!important;
	}
	
	footer {
    	height: 120px;
	}
	
	#footer-container {
		padding: 0 50px;
		margin: 20px auto;
	}
	
}


@media only screen and (max-width: 1024px) {	
	
	h1 {
		font-size: 40px;
		line-height: 50px;
	}
	
	h2 {
		font-size: 30px;
		line-height: 40px;
	}
	
	.has-mask {
		margin-bottom:0px;
	}
	
	.percentage {
    	font-size: 5.5vw;
	}
	
	.trackbar {
    	margin-top: 30px;
		width: 55vw;
	}
	
	.headphones {
    	margin-top: 40px;
	}
	
	header {
    	height: 120px;
	}
	
	.row_padding_left {
		padding-left: 40px;
	}
	
	.row_padding_right {
		padding-right: 40px;
	}
	
	.row_padding_all {
		padding: 8vw 40px;
	}
	
	header {
		position: absolute;
		height:80px;
	}
	
	#header-container {
    	padding: 0;
		margin: 0 auto;
	}
	
	#logo {
		left: 40px;
	}
	
	#burger-wrapper {
		display: block!important;
		right: 0px;
	}
	
	#menu-burger {
		display: block;
		position: absolute;
		right: 40px;
		top: 32px;
	}
	
	nav {
		padding-top:80px;
	}
	
	.flexnav {
		box-sizing:border-box;
		padding:0 50px;
		background: transparent;
		-webkit-transition: all .5s ease-in-out;
		transition: all .5s ease-in-out;
	}
	
	.flexnav li a, .flexnav li ul li a {
    	background: transparent;
	}
	
	.flexnav li a {
		color: #999;
	}
	
	.flexnav li ul li a:hover, .flexnav li ul li a.active {
		color:#fff;
	}
	
	.flexnav li ul li a {
		border-top: 1px solid rgba(255,255,255,0.15);
	}
	
	.flexnav ul li ul li a {
		background: transparent;
	}
	
	.flexnav li ul li ul li a {
		padding: 20px 40px;	
	}
	
	.flexnav li.link {
		border-bottom: 1px solid rgba(255,255,255,0.15);
	}
	
	.flexnav li.link:last-child {
		border-bottom:none;
	}
	
	.flexnav a.link::before {
		display:none;
	}
	
	.flexnav .touch-button .navicon {
		font-style: normal!important;
	}
	
	.nav-height {
		overflow-y: scroll;
		position: relative;
		height: 100%;
		padding: 40px 0;
		padding-right: 30px;
		width: calc(100% + 30px);
		box-sizing: border-box;
	}
	
	nav {
		height: 100vh;
		position: fixed;
		width: 100%;
		box-sizing: border-box;
		visibility: visible;
		pointer-events:none;
		opacity:0;
		padding:20px 0;		
		-webkit-transition: all 0.2s ease-in 0.6s;
		transition: all 0.2s ease-in 0.6s;
	}
	
	nav.open {
		visibility:visible;
		pointer-events:initial;
		opacity:1;
		background-color: rgba(0, 0, 0, 0.8);
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
	}
	
	.flexnav {
    	max-height: 2000px;
		-webkit-transition: all 0.2s ease-in 0.6s;
		transition: all 0.2s ease-in 0.6s;
	}
	
	.flexnav.flexnav-show {
		-webkit-transition: all .3s ease-out 0.2s;
		transition: all .3s ease-out 0.2s;	
	}
	
	.flexnav li {
		padding:0;
		text-align:center;
		line-height: 5vw;
		-webkit-transition: color .15s ease-out 0s;
		transition: color .15s ease-out 0s;	
	}
	
	.flexnav:hover li  {
		color:rgba(255,255,255,0.4)
	}
	
	.flexnav li:hover {
		color:rgba(255,255,255,1)
	}
	
	.flexnav .touch-button {
		width: 100%;
		display: block;
		height: calc(5vw + 20px);
	}
	
	.flexnav .touch-button .navicon {
		display:none;
	}
	
	.flexnav li.link {
		border-bottom: none;	
	}
	
	.flexnav li a {
		font-weight: 700;
		font-family: 'Poppins', sans-serif;
		font-size: 4.5vw;
		padding:10px 0;
		color:inherit;
		text-transform: uppercase;
	}
	
	.light-content .flexnav li a {
		color: inherit;
	}
	
	.flexnav li ul {
		margin-bottom:20px;
	}
	
	.flexnav li ul li a {
		padding: 10px 0;
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		border-top: none;
	}
	
	#menu-burger span.touch-button {
		display:none;
	}
	
	.parallax-wrap {
		transform:none!important;
	}
	
	.parallax-element {
		transform:none!important;
	}
	
	#magic-cursor {
		display:none;
	}
	
	.scroll-down-wrap, .scroll-down-wrap.no-border {
		bottom: 40px;
	}
	
	.hero-title, .page-title {
    	font-size: 5.5vw;
	}
	
	#main-page-content {
		padding: 0 40px;
	}
	
	footer {
		overflow: visible;
	}
	
	#footer-container {
		padding: 0 40px;
	}
	
	.destroy {
		display:none;
	}
	
	.has-parallax figcaption {
		display:none;
	}
	
	.button-wrap.left {
		display:none;
	}
	
	.footer-button-wrap {
    	bottom: 80px;
	}
	
	.socials-wrap {
		width: 240px;
		height: 40px;
		float:none;
		margin:0 auto;
		transform: translateX(0px) translateY(20px)!important;
		-webkit-transform: translateX(0px) translateY(20px)!important;
	}
	
	.socials-wrap:hover {
		transform: translateY(20px);
		-webkit-transform: translateY(20px);
	}
	
	.socials-icon, .socials-text {
		display:none;
	}
	
	.socials-wrap .socials {
		margin-top: 5px;
		text-align: center;
		transform: translateX(0px);
		-webkit-transform: translateX(0px);	
	}
	
	.socials-wrap .socials li {
		opacity: 1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);
	}
	
	.socials li {
		margin-right: 10px;
		line-height: 30px;
		height: 30px;
		margin: 0 5px auto;
		display: inline-block;
		float: none;
	}
	
	.socials li a {
		height: 30px;
		line-height: 30px;
	}
	
}


@media only screen and (max-width: 767px) {
	
	.percentage {
    	font-size: 7vw;
	}
	
	.row_padding_left {
		padding-left: 30px;
	}
	
	.row_padding_right {
		padding-right: 30px;
	}
	
	.row_padding_all {
		padding: 8vw 30px;
	}
	
	.admin-bar header {
		top: 46px;
	}
	
	#logo {
		left: 30px;
	}
	
	#menu-burger {
		right: 30px;
		top: 27px;
	}
	
	.flexnav {
    	padding: 0 40px;
	}
	
	.flexnav li {
    	line-height: 7vw;
	}
	
	.flexnav li a {
		font-size: 7vw;
	}
	
	.destory {
		display:none;
	}
		
	figcaption {
		background-color: rgba(0,0,0,0.3);
		bottom: 0px;
		font-size: 10px;
		padding: 5px 10px;
		right: 0px;
		border-radius: 0;
	}
	
	.one_half {
    	width: 100%!important;
	}
	
	.one_half {
    	padding-right: 0!important;
	}
	
	.one_half.last {
    	padding-left: 0!important;
	}

	.one_third {
		width: 100%!important;
	}	

	.one_fourth {
		width: 100%!important;
	}	

	.one_fifth {
		width: 100%!important;
	}	

	.one_sixth {
		width: 100%!important;
	}	

	.two_fifth {
		width: 100%!important;
	}	

	.two_fourth {
		width: 100%!important;
	}		

	.two_third {
		width: 100%!important;
	}	

	.three_fifth {
		width: 100%!important;
	}	

	.three_fourth {
		width: 100%!important;
	}	

	.four_fifth {
		width: 100%!important;
	}	

	.five_sixth {
		width: 100%!important;
	}	

	.one_half, .one_third, .two_third, .three_fourth, .one_fourth, .two_fourth, .one_fifth, .two_fifth, .three_fifth, .four_fifth, .one_sixth, .five_sixth {
		margin-bottom: 20px!important;
		margin-right: 0%!important;
	}
	
	.text-align-center p.title-has-line::after, #page-nav p.title-has-line::after {
		display:none;
	}
	
	p {
    	font-size: 14px;
	}
	
	.hero-title, .page-title {
    	font-size: 7vw;
	}
	
	.hero-title span, .page-title span {
    	min-width: 10px;
	}
	
	#main-page-content {
		padding: 0 30px;
	}
	
}


@media only screen and (max-width: 479px) {	
	
	.percentage {
    	font-size: 9vw;
	}
	
	.row_padding_left {
		padding-left: 20px;
	}
	
	.row_padding_right {
		padding-right: 20px;
	}
	
	.row_padding_all {
		padding: 8vw 20px;
	}
	
	hr {
		height: 10px;
	}
	
	#logo {
		left: 20px;
	}
	
	#menu-burger {
		right: 20px;
	}
	
	.flexnav {
    	padding: 0 20px;
	}
	
	.flexnav li {;
    	line-height: 9vw;
	}
	
	.flexnav li a {
		font-size: 9vw;
	}
	
	.flexnav li ul li a {
    	padding: 5px 0;
	}
	
	p.title-has-line::after {
		display:none;
	}
	
	#main-page-content {
		padding: 0 20px;
	}
	
	#hero.has-image #hero-styles, #hero.error #hero-styles {
		transform: translateY(-35vh);
		-webkit-transform: translateY(-35vh);
	}
	
	.hero-title, .page-title {
    	font-size: 9vw;
	}
	
	.hero-title span, .page-title span {
    	min-width: 10px;
	}
	
	#footer-container {
		padding: 0 10px;
	}
	
}

@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
    
	#rotate-device {
		display:block;
	}
}
