@use '@angular/material' as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.all-legacy-component-typographies();
@include mat.core();
@include mat.legacy-core();
@import 'variables.scss';

$wiesel-blue: (
    50 : #ffffff,
    100 : #fdfdfe,
    200 : #dde3e6,
    300 : #b3c1c8,
    400 : #a2b2bb,
    500 : #90a4ae,
    600 : #7e95a1,
    700 : #6d8794,
    800 : #5f7783,
    900 : #536771,
    A100 : #b7b5a2,
    A200 : #e9e7d3,
    A400 : #878573,
    A700 : #737164,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$wiesel-green: (
    50 : #ffffff,
    100 : #fdfdfd,
    200 : #dde4e0,
    300 : #b6c4bb,
    400 : #a5b7ab,
    500 : #94a99b,
    600 : #839b8b,
    700 : #728e7b,
    800 : #647d6c,
    900 : #576c5e,
    A100 : #cb9ca1,
    A200 : #ead1d3,
    A400 : #ceb3c0,
    A700 : #9a7d86,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary-color: map-get($wiesel-green, 500);
$secondary-color: map-get($wiesel-blue, 500);
$black: #000000;
$white: #FFFFFF;

$wiesel-theme-primary: mat.define-palette($wiesel-green, 500);
$wiesel-theme-accent: mat.define-palette($wiesel-blue, 500);
$wiesel-theme-success: mat.define-palette($wiesel-green, 800);
$wiesel-theme-warn: mat.define-palette(mat.$red-palette, 300);
$wiesel-light-theme: mat.define-light-theme($wiesel-theme-primary, $wiesel-theme-accent, $wiesel-theme-warn);

$wiesel-theme-primary-dark: mat.define-palette($wiesel-green, 500);
$wiesel-theme-accent-dark: mat.define-palette($wiesel-blue, 200);
$wiesel-dark-theme: mat.define-dark-theme($wiesel-theme-primary-dark, $wiesel-theme-accent-dark, $wiesel-theme-warn);

@include mat.all-component-themes($wiesel-light-theme);
@include mat.all-legacy-component-themes($wiesel-light-theme);

body.wiesel-dark {
  @include mat.all-component-themes($wiesel-dark-theme);
  @include mat.all-legacy-component-themes($wiesel-dark-theme);
}

body.wiesel-dark .mat-stepper-horizontal,
body.wiesel-dark .mat-stepper-vertical {
  background-color: #000!important;
}